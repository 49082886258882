import { Pipe, PipeTransform } from '@angular/core';
import { TreeNode } from 'primeng/api';
import { TruncatePipe } from './truncatepipe';

@Pipe({
  name: 'truncatetreedata'
})

export class TruncateTreeDataPipe implements PipeTransform {
  
  transform(data: any[]): TreeNode[] {
    let count;
    for (count = 0; count < data.length; count++) {
      data[count].fullLabel = data[count].label;
      if (data[count]?.label?.length > 30) {
        data[count].label = data[count].label.slice(0, 30) + '...';
      }
      if (data[count].children != null) {
        this.transform(data[count].children);
      }
    }
    return data;
  }
}
