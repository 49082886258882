import { Dropdown } from "./dropdown";

export class Documents {
  public mcsHierarchyClient?: string;
  public mcsHierarchyEngagement?: string;
  public mcsHierarchyTaxpayer?: string | null;
  public mcsHierarchyWorkflow?: string;

  public mcsHierarchyClientId?: string;
  public mcsHierarchyEngagementId?: string;
  public mcsHierarchyTaxpayerId?: string | null;
  public mcsHierarchyWorkflowId?: string;

  public mcsDoctypeName?: string;
  public mcsDoctypeGroupName?: string;
  public mcsDoctypeDescName?: string;
  public mcsDoctype2ndDescName?: string;

  public mcsDoctype?: any;
  public mcsDoctypeGroup?: any;
  public mcsDoctypeDesc?: any;
  public mcsDoctype2ndDesc?: any;

  public workflowDD?: Dropdown[];

  public mcsDoctypeDD?: Dropdown[];
  public mcsDoctypeGroupDD?: Dropdown[];
  public mcsDoctypeDescDD?: Dropdown[];
  public mcsDoctype2ndDescDD?: Dropdown[];

  public stateCode?: number;
  public statusCode?: number;
  public mcsDocumentId?: string;
  public modifiedOn?: Date;
  public mcsName?: string;
  public versionNumber?: number;
  public mcsSurePrepOCROverride?: boolean | null;
  public createdOn?: Date;
  public mcsDocLastModified?: Date;
  public mcsEngagementTaxYear?: string;
  public mcsWorkflowTaxYear?: string;
  public selectedTaxYear?: string;
  public mcsSentToSurePrepDate?: Date;
  public timeZoneRuleVersionNumber?: string;
  public uTCConversionTimeZoneCode?: string;
  public overriddenCreatedOn?: Date;
  public importSequenceNumber?: number;
  public mcsSubstantiveReviewerSignOffDate?: Date;
  public mcsDetailReviewerSignOffDate?: Date;
  public mcsSentToGlobalPortalDate?: Date;
  public mcsSurePrepSelectionStatus?: string;
  public mcsProjectManagerSignOffDate?: Date;
  public mcsDocUserDesc?: string;
  public mcsPreparerSignOffDate?: Date;
  public mcsOfficeAdminSignOffDate?: Date;
  public mcsSignerSignOffDate?: Date;
  public mcsDocUrl?: string;
  public mcsApplicableTaxYear?: string;
  public mcsUserDefinedDescription?: string;
  public mcsPreparer?: string;
  public mcsDetailReviewer?: string;
  public mcsSubstantiveReviewer?: string;
  public mcsSigner?: string;
  public mcsOfficeAdmin?: string;
  public mcsProjectManager?: string;
  public userEmailId: string | undefined;
  public mcsIsCheckedOut?: boolean;
  public mcsOwner?: string;
}
