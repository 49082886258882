export const messages = {
  archivedValidationError: "One or more document(s) selected are in archived status. Please try with documents which are not archived.",
  archivedValidationErrorSingleDoc: "The selected document is in archived status. Please try with document which is not archived.",
  holdDocumentsTriggered: "A message has been triggered to service bus to put document(s) on hold.",
  unholdDocumentsTriggered: "A message has been triggered to service bus to remove document(s) from hold.",
  holdDocuments: "Hold Document(s)",
  unholdDocuments: "Unhold Document(s)",
  uploadSizeExceeded: " can not be uploaded since it exceeded the maximum file size of 2 GB.",
  uploadfileCountExceeded: "Maximum number of files exceeded. Please select atmost 10 documents to upload.",
  invalidFilename: " contains special characters. Please rename and try again.",
  fileUpload: "File Upload",
  multipleDocCheckoutError: "Please select only one document at a time to check out.",
  multipleDocCheckinError: "Please select only one document at a time to check in.",
  invalidDocCheckoutError: "The selected document is pending upload in SharePoint. Please select a document which is successfully uploaded to SharePoint.",
  checkOutDocument: "Check Out Document",
  checkInDocument: "Check In Document",
  checkOutSuccess: "The selected document is checked out successfully.",
  checkInSuccess: "The selected document is checked in successfully.",
  checkOutValidation: "The selected document is already Checked out.",
  checkInValidation: "The selected document is already Checked in.",
  checkOutFailure: "Document check out failed.",
  checkInFailure: "Document check in failed.",
  errorOccurred: "We're experiencing technical difficulties. Please try again later.",
  failedTreeView: "Oops! We encountered a problem fetching the document tree.",
  failedSignOffMatrix: "Oops! We encountered a problem fetching the sign matrix.",
  failedUpload: "Oops! We encountered a problem while uploading the document(s).",
  failedSignOff: "Oops! We encountered a problem while signing off the document.",
  failedRemoveSignOff: "Oops! We encountered a problem while removing the sign off from the document.",
  failedDelete: "Oops! We encountered a problem while deleting the document(s).",
  failedRecovered: "Oops! We encountered a problem while recovering the document(s).",
  failedFetch: "Oops! We encountered a problem while fetching the record.",
  failedUpdate: "Oops! We encountered a problem while updating the record.",
  failedSearch: "Oops! We encountered a problem while searching.",
  invalidOperation: "An operation is already in-progress/error in upload for the selected document(s). Please select any other valid document(s) or try again later.",
  archiveDocuments: "Archive Document(s)",
  unarchiveDocuments: "Unarchive Document(s)",
}
