import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
//import { BreezeSetupGuard } from '../breeze-setup/breeze-setup.guard';    Commented out to bypass the DB.

@Injectable({
    providedIn: 'root'
})
export class AppGuard implements CanActivate {
    constructor(
        private msalGuard: MsalGuard,
        //private breezeGuard: BreezeSetupGuard
    ) { }

    private reroute() {
        return false;
    }

    public async canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean | UrlTree> {
        if (await this.msalGuard.canActivate(next, state).toPromise().catch(() => this.reroute()) === false) {
            return this.reroute();
        }

        //if (await this.breezeGuard.canActivate(next, state).catch(() => this.reroute()) === false) {
        //    return this.reroute();
        //}

        return true;
    }

}
