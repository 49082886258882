import { HttpParams } from '@angular/common/http';
import { Component, Inject, OnInit, ViewChild, Input } from '@angular/core';
import { MessageService, TreeNode } from 'primeng/api';
import { CustomTreeNode } from '../treeview/CustomTreeNode';
import { CommonService } from '../services/common/common.service';
import { Tree } from 'primeng/tree';
import { Year } from '../../model/year';
import { LocalService } from '../services/local.service';
import { Constant } from '../../model/constant.enums';
import { Context } from '../../model/context';
import { documentsService } from '../services/documents.service';
import { messages } from '../messages/message.constant';

const TREE_DATA: TreeNode[] = [];

@Component({
  selector: 'app-treeview',
  templateUrl: './treeview.component.html',
  styleUrls: ['./treeview.component.css'],
  providers: [MessageService]
})
export class TreeviewComponent implements OnInit {

  constructor(private messageService: MessageService, private commonService: CommonService,
    private localService: LocalService, private documentService: documentsService) {
  }

  parsedContext!: Context;

  engagementYears: any = [];

  workflowYears: any = [];

  selectedEngagementYears: Year | undefined;

  selectedWorkflowYears: Year | undefined;

  files: TreeNode[] = TREE_DATA;

  originalFiles: TreeNode[] = TREE_DATA;

  treeval: string = '';

  owner: string = '';

  // @ts-ignore: Object is possibly 'null'.
  selectedFile: TreeNode | undefined
  // @ts-ignore: Object is possibly 'null'.
  @ViewChild('tt') tree: Tree;

  isTreeLoading: boolean = false;

  ngOnInit() {
    this.files = [];
    this.setContext();
    this.nodeSelectOnBreadcrumb();
    this.refreshComponent();

    const currentYear: number = new Date().getFullYear();

    for (let year = currentYear + 5; year >= currentYear - 25; year--) {
      this.engagementYears.push({ name: year.toString(), code: year.toString() + ' - Engagement' });
      this.workflowYears.push({ name: year.toString(), code: year.toString() + ' - Workflow' });
    }
  }

  private setContext() {
    let treecontext = this.localService.getData(Constant[Constant.context]);
    this.parsedContext = JSON.parse(treecontext ?? '');
    this.getDocumentsTree(this.parsedContext.recId, this.parsedContext.recType, this.parsedContext.clientId, this.parsedContext.clientName);
  }

  getDocumentsTree(recId: string, recType: string, clientId: string, clientName: string) {
    let parameters = new HttpParams();
    parameters = parameters.append('recId', recId.toLocaleLowerCase());
    parameters = parameters.append('recType', recType.toLocaleLowerCase());
    parameters = parameters.append('clientId', clientId.toLocaleLowerCase());
    parameters = parameters.append('clientName', clientName.toLocaleLowerCase());
    this.isTreeLoading = true;
    this.documentService.getDocumentsList('getdocumentstree', parameters)
      .subscribe({
        next: (result) => {
          console.log(result);
          this.originalFiles = result.documentTree;
          this.files = this.originalFiles;
          this.owner = result.owner;
          this.parsedContext.owner = this.owner;
          this.localService.saveData(Constant[Constant.context], JSON.stringify(this.parsedContext.owner));
          setTimeout(() => {
            // @ts-ignore: Object is possibly 'null'.
            this.selectedFile = this.findSelectableNode(this.originalFiles, recId);
            this.nodeSelect({ node: this.selectedFile });
          }, 500);
          this.isTreeLoading = false;
        },
        error: (error) => {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: messages.failedTreeView });
          console.error(error);
          this.isTreeLoading = false;
        }
      });
  }

  nodeSelect(evt: any): void {
    console.log(evt);
    let navigationNodes = [];
    let node = evt.node;
    while (node.parent) {
      navigationNodes.push(node);
      node = node.parent;
    }
    navigationNodes.push(node);

    let nodes = this.getAllNodes(evt.node)
      .filter(function (value) {
        return value.icon?.includes("pi-file");
      })
      .map(function (value: any) {
        return value.key;
      });

    let context = {
      recId: navigationNodes[0].recordId,
      recType: this.getRecTypeFromType(navigationNodes[0].type),
      clientId: node.recordId,
      clientName: node.label,
      type: navigationNodes[0].type,
      owner: this.owner
    }
    this.localService.saveData(Constant[Constant.context], JSON.stringify(context));
    this.commonService.refreshDocSelectionOnNodeClick(true);
    this.commonService.nodeClicked(navigationNodes);
    this.commonService.clickedNodeToSignOff(nodes);
  }

  getAllNodes<T>(node: TreeNode<T>): TreeNode<T>[] {
    let nodes: TreeNode<T>[] = [];
    nodes.push(node);

    if (node.children) {
      for (const child of node.children) {
        nodes = nodes.concat(this.getAllNodes(child));
      }
    }

    return nodes;
  }

  filterData(filter: any): void {
    if (!filter.target.value) {
      // if there's no filter, show all nodes
      this.files = this.originalFiles;
      return;
    }

    this.tree._filter(filter.target.value);
  }

  private findSelectableNode(nodes: CustomTreeNode[], key: string): TreeNode | null {
    for (const node of nodes) {
      if (node.recordId?.toLocaleLowerCase() === key.toLocaleLowerCase()) {
        return node;
      }

      if (node.children != null) {
        const foundNode = this.findSelectableNode(node.children, key);
        if (foundNode != null) {
          return foundNode;
        }
      }
    }
    return null;
  }

  private findSelectableNodeByKeyForBreadcrumb(nodes: CustomTreeNode[], key: string): TreeNode | null {
    for (const node of nodes) {
      if (node.key?.toLocaleLowerCase() === key.toLocaleLowerCase()) {
        return node;
      }

      if (node.children != null) {
        const foundNode = this.findSelectableNodeByKeyForBreadcrumb(node.children, key);
        if (foundNode != null) {
          return foundNode;
        }
      }
    }
    return null;
  }


  getRecTypeFromType(type: string): string {
    let recType = "";
    if (type == 'client') {
      recType = 'account';
    }
    else if (type == 'taxpayer') {
      recType = 'contact';
    }
    else if (type == 'taxyear') {
      recType = 'taxyear';
    }
    else if (type == 'engagement') {
      recType = 'mcs_engagement';
    }
    else if (type == 'workflow') {
      recType = 'msdyn_project';
    }
    return recType;
  }

  refreshComponent() {
    this.commonService.refreshTreeOnOperationCompleteEvent.subscribe((val: boolean) => {
      if (val == true) {
        //this.getDocumentsTree(this.parsedContext.recId, this.parsedContext.recType, this.parsedContext.clientId, this.parsedContext.clientName);
        //this.selectedCustomers = [];
      }
    });
  }

  // Function to make node selected on click of breadcrumb
  nodeSelectOnBreadcrumb() {
    this.commonService.breadcrumbClickedEvent.subscribe((breadcrumbItemId: string) => {
      setTimeout(() => {
        this.selectedFile = this.findSelectableNodeByKeyForBreadcrumb(this.originalFiles, breadcrumbItemId) ?? undefined;
        this.nodeSelect({ node: this.selectedFile });
      }, 500);
    });
  }

}
