import { AfterViewInit, Component, Inject, ViewChild } from '@angular/core';
import { Table } from 'primeng/table';
import { CommonService } from '../services/common/common.service';
import { SignOffMatrix } from '../../model/signoffmatrix';
import { documentsService } from '../services/documents.service';
import { LocalService } from '../services/local.service';
import { Constant } from '../../model/constant.enums';
import { Context } from '../../model/context';
import { DocumentTreeRequest } from '../../model/documentTreeRequest';
import { GraphQLService } from '../services/graphQL.service';
import { ContentSearchDTO } from '../../model/contentSearchDTO';
import { MessageService } from 'primeng/api';
import { messages } from '../messages/message.constant';

let ELEMENT_DATA: SignOffMatrix[]

@Component({
  selector: 'app-matrix',
  templateUrl: './matrix.component.html',
  styleUrls: ['./matrix.component.css'],
})
export class MatrixComponent {

  constructor(private commonService: CommonService, private documentService: documentsService,
    private localService: LocalService, private graphQLService: GraphQLService, private messageService: MessageService) {
  }

  parsedContext!: Context;

  customers: SignOffMatrix[] = ELEMENT_DATA;

  filteredCustomers: SignOffMatrix[] = ELEMENT_DATA;

  selectedCustomers: SignOffMatrix[] = [];

  doctypedescordoctype2nddesc: string = '';

  highlightKey: string[] = [];

  isSignOffMatrixDataLoading: boolean = false;

  disableRowSelection: boolean = false;

  signOffMatrixRequest: DocumentTreeRequest | undefined;

  searchInput!: any;

  @ViewChild('dt1') dt: Table | undefined;

  ngOnInit() {
    this.customers = [];
    this.filteredCustomers = [];
    this.disableRowSelection = false;
    this.setContext();
    this.nodeSubscription();
    this.disableRowSelectionSubscription();
    this.getDocumentsTreeForSelectedNode();
    this.refreshComponent();
    this.refreshSelection();
  }

  private nodeSubscription() {
    this.commonService.nodeClickedSubscription
      .subscribe((data: string[]) => {
        if (data.length !== 0 && typeof (ELEMENT_DATA) !== 'undefined') {
          this.customers = ELEMENT_DATA;
          let matchedCustomers = this.customers.filter(function (customer) {
            return data.includes(customer.key);
          });
          this.customers = matchedCustomers;
          this.filteredCustomers = matchedCustomers;
        }
      });
  }

  private setContext() {
    let treecontext = this.localService.getData(Constant[Constant.context]);
    this.parsedContext = JSON.parse(treecontext ?? '');
  }

  private getSignOffMatrixData() {
    if (this.signOffMatrixRequest !== undefined) {
      this.searchInput = "";
      this.isSignOffMatrixDataLoading = true;
      this.commonService.buttonDisableForDataLoading(true);
      this.documentService.getDocumentTreeForSelectedNode(this.signOffMatrixRequest).subscribe({
        next: (result: any) => {
          let mappedData = result.map((item: any) => {
            return {
              position: item.mcsHierarchyWorkflow,
              document: item.mcsName,
              taskname: item.mcsTaskName,
              userdefineddescription: item.mcsUserDefinedDescription,
              preparer: item.mcsPreparer,
              detailreviewer: item.mcsDetailReviewer,
              substantivereviewer: item.mcsSubstantiveReviewer,
              requiredreviewer: item.mcsRequiredReviewer,
              signer: item.mcsSigner,
              officeadmin: item.mcsOfficeAdmin,
              projectmanager: item.mcsProjectManager,
              signersignoffdate: item.mcsSignerSignOffDate == '0001-01-01T00:00:00' ? null : item.mcsSignerSignOffDate,
              preparersignoffdate: item.mcsPreparerSignOffDate == '0001-01-01T00:00:00' ? null : item.mcsPreparerSignOffDate,
              detailreviewersignoffdate: item.mcsDetailReviewerSignOffDate == '0001-01-01T00:00:00' ? null : item.mcsDetailReviewerSignOffDate,
              substantivereviewersignoffdate: item.mcsSubstantiveReviewerSignOffDate == '0001-01-01T00:00:00' ? null : item.mcsSubstantiveReviewerSignOffDate,
              requiredreviewersignoffdate: item.mcsRequiredReviewerSignOffDate == '0001-01-01T00:00:00' ? null : item.mcsRequiredReviewerSignOffDate,
              officeadminsignoffdate: item.mcsOfficeAdminSignOffDate == '0001-01-01T00:00:00' ? null : item.mcsOfficeAdminSignOffDate,
              projectmanagersignoffdate: item.mcsProjectManagerSignOffDate == '0001-01-01T00:00:00' ? null : item.mcsProjectManagerSignOffDate,
              senttogpdate: item.mcsSentToGlobalPortalDate == '0001-01-01T00:00:00' ? null : item.mcsSentToGlobalPortalDate,
              applicabletaxyear: item.mcsApplicableTaxYear,
              senttosureprepdate: item.mcsSentToSurePrepDate == '0001-01-01T00:00:00' ? null : item.mcsSentToSurePrepDate,
              lastmodifieddate: item.mcsDocLastModified == '0001-01-01T00:00:00' ? null : item.mcsDocLastModified,
              doctypedescordoctype2nddesc: item.mcsDoctype2ndDesc != '' ? item.mcsDoctype2ndDesc : item.mcsDoctypeDesc,
              market: "",
              region: "",
              key: item.mcsDocumentId,
              url: item.mcsDocUrl,
              doctypedescription: item.mcsDoctypeDesc,
              doctype2nddescription: item.mcsDoctype2ndDesc,
              statuscode: item.statusCode,
              retentionlabel: item.mcsRetentionLabel,
              doccheckoutstatus: item.mcsDocCheckOutStatus,
              nativeAppUrl: item.mcsNativeAppUrl ? item.mcsNativeAppUrl : item.mcsDocUrl,
              taxpayer: item.mcsHierarchyTaxpayer,
              engagement: item.mcsHierarchyEngagement,
              engagementnumber: item.mcsEngagementNumber,
              workflow: item.mcsHierarchyWorkflow,
              year: item.mcsEngagementTaxYear,
              workflowyear: item.mcsWorkflowTaxYear,
              retentionLabelAppliedOn: item.mcsRetentionLabelAppliedOn == '0001-01-01T00:00:00' ? null : item.mcsRetentionLabelAppliedOn,
              modifiedOn: item.modifiedOn == '0001-01-01T00:00:00' ? null : item.modifiedOn
            };
          });

          ELEMENT_DATA = mappedData;
          this.customers = ELEMENT_DATA;
          this.filteredCustomers = ELEMENT_DATA;
          console.log(ELEMENT_DATA);
          this.isSignOffMatrixDataLoading = false;
          this.commonService.buttonDisableForDataLoading(false);
        },
        error: (error) => {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: messages.failedSignOffMatrix });
          console.error(error);
          this.isSignOffMatrixDataLoading = false;
        }
      });
    }
  }

  private getDocumentsTreeForSelectedNode() {
    this.commonService.getDocumentTreeOnNodeEvent.subscribe((request: DocumentTreeRequest) => {
      this.signOffMatrixRequest = request;
      this.getSignOffMatrixData();
    });
  }

  clear(table: Table) {
    table.clear();
  }

  applyFilterGlobal($event: any, stringVal: any) {
    this.dt!.filterGlobal(($event.target as HTMLInputElement).value, stringVal);
  }


  refreshComponent() {
    this.commonService.refreshOnOperationCompleteEvent.subscribe((val: boolean) => {
      if (val == true) {
        this.getSignOffMatrixData();
        this.commonService.refreshDocSelectionOnNodeClick(true);
      }
    });
  }

  isSignoffDateChanged(modifiedon: Date, lastmodifieddate: Date, preparersignoffdate: Date, detailreviewersignoffdate: Date, key: string): boolean {
    if (modifiedon != null && lastmodifieddate != null && preparersignoffdate != null && detailreviewersignoffdate != null) {      
      const modifiedOn = this.getLocalTimeFromUTCTime(modifiedon);
      const lastModifiedDate = this.getLocalTimeFromUTCTime(lastmodifieddate);
      const preparerSignOffDate = this.getLocalTimeFromUTCTime(preparersignoffdate);
      const detailReviewerSignOffDate = this.getLocalTimeFromUTCTime(detailreviewersignoffdate);
      const higherTime = this.getMaxDate(preparerSignOffDate, detailReviewerSignOffDate).getTime();
      var result = lastModifiedDate.getTime() > higherTime || modifiedOn.getTime() > higherTime;
      if (result == true) {
        this.highlightKey.push(key);
      }
      return result;
    }
    else return false;
  }

  // Convert UTC time to local time.
  getLocalTimeFromUTCTime(dateTimeInput: any): Date {
    if (dateTimeInput.endsWith('Z')) {
      const dateWithZ = new Date(dateTimeInput);
      return new Date(dateWithZ.getTime() + dateWithZ.getTimezoneOffset() * 60000); // Adding timezone offset in minutes.
    }
    return new Date(dateTimeInput);
  }

  shouldHighlightRow(customerKey: string): boolean {
    return this.highlightKey.includes(customerKey);
  }

  getMaxDate(preparersignoffdate: Date, detailreviewersignoffdate: Date): Date {
    const maximum = preparersignoffdate.getTime() > detailreviewersignoffdate.getTime() ? preparersignoffdate : detailreviewersignoffdate;
    return maximum;
  }

  onRowSelect(event: any) {
    this.commonService.rowSelected(this.selectedCustomers);
    this.commonService.rowSelectUnselect(true);
  }

  onRowUnselect(event: any) {
    this.commonService.rowSelected(this.selectedCustomers);
    this.commonService.rowSelectUnselect(true);
  }

  onHeaderCheckboxToggle(event: any) {
    if (event.checked) {
      this.commonService.rowSelected(this.selectedCustomers);
    }
    else {
      this.commonService.rowSelected(this.selectedCustomers);
    }
  }

  refreshSelection() {
    this.commonService.refreshDocSelectionEvent.subscribe((val: boolean) => {
      if (val == true) {
        this.selectedCustomers = [];
        this.commonService.rowSelected(this.selectedCustomers);
      }
    });

  }

  disableRowSelectionSubscription() {
    this.commonService.disableRowSelectOnPanelEvent.subscribe((val: boolean) => {
      if (val == true) {
        this.disableRowSelection = true;
      }
      else {
        this.disableRowSelection = false;
      }
    });
  }

  // Content search.
  contentSearch(searchText: any) {
    if (searchText) {
      const request: ContentSearchDTO = {
        searchText: searchText,
        clientId: this.signOffMatrixRequest?.clientId ? this.signOffMatrixRequest?.clientId : this.parsedContext.clientId,
        engagementId: this.signOffMatrixRequest?.engagementId ? this.signOffMatrixRequest?.engagementId : "",
        taxpayerId: this.signOffMatrixRequest?.taxpayerId ? this.signOffMatrixRequest?.taxpayerId : "",
        workflowId: this.signOffMatrixRequest?.workflowId ? this.signOffMatrixRequest?.workflowId : ""
      };
      this.isSignOffMatrixDataLoading = true;
      this.commonService.buttonDisableForDataLoading(true);
      this.graphQLService.contentSearch(request).subscribe({
        next: (response) => {
          if (response && response.length > 0) {
            let matchedCustomers = this.customers.filter(x => response.includes(x.key));
            this.filteredCustomers = matchedCustomers;
          } else {
            this.filteredCustomers = [];
          }
          this.isSignOffMatrixDataLoading = false;
          this.commonService.buttonDisableForDataLoading(false);
        },
        error: (error) => {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: messages.failedSearch });
          console.error(error);
          this.isSignOffMatrixDataLoading = false;
        }
      });
    }
  }

  // On Search box change.
  onSearchChange(event: any) {
    if (event === "") {
      this.filteredCustomers = this.customers;
    }
  }


  getFileTypeIconClass(fileName: string): string {
    let fileExtension = fileName.split('.').pop() ?? '';
    const excelExtensions = ['xlsx', 'xls', 'xlsm', 'xlt', 'xltx', 'xltm'];
    const docExtensions = ['docx', 'doc', 'docm', 'dot', 'dotx', 'dotm'];
    const pptExtensions = ['pptx', 'ppt', 'pptm', 'pot', 'potx', 'potm'];

    if (excelExtensions.includes(fileExtension)) {
      return '../../assets/icons/excel.png';
    } else if (fileName.includes('.pdf')) {
      return '../../assets/icons/pdf.png';
    } else if (docExtensions.includes(fileExtension)) {
      return '../../assets/icons/word.png';
    } else if (pptExtensions.includes(fileExtension)) {
      return '../../assets/icons/ppt.png';
    } else if (fileName.includes('.vsdx')) {
      return '../../assets/icons/visio.png';
    } else if (fileName.includes('.msg')) {
      return '../../assets/icons/outlook.png';
    } else if (fileName.includes('.one')) {
      return '../../assets/icons/onenote.png';
    } else if (fileName.includes('.jpeg') || fileName.includes('.png')) {
      return '../../assets/icons/image.png';
    } else {
      return '../../assets/icons/file.png';
    }
  }

}
