import { Component, ViewChild, Renderer2, ElementRef, HostListener, Inject, Output, EventEmitter } from '@angular/core';
import { MenuItem, MessageService, TreeNode } from 'primeng/api';
import { FileUpload } from 'primeng/fileupload';
import { Documents } from '../../model/documents';
import { documentsService } from '../services/documents.service';
import { uploadService } from '../services/upload.service';
import { Dropdown } from '../../model/dropdown';
import { CommonService } from '../services/common/common.service';
import { LocalService } from '../services/local.service';
import { Constant } from '../../model/constant.enums';
import { Context } from '../../model/context';
import { forkJoin, interval, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { Entities } from '../../model/entities';
import { SignOffMatrix } from '../../model/signoffmatrix';
import { TreeTypes } from '../../model/treetype';
import { signOffDTO } from '../../model/signOffDTO';
import { Router } from '@angular/router'
import { GetDocumentMetadata } from '../../model/getMetadata';
import { ConfirmationService } from 'primeng/api';
import { HttpParams } from '@angular/common/http';
import { MsalService } from '@azure/msal-angular';
//import { get } from 'https';
import { getRolesDTO } from '../../model/getRolesDTO';
import { MetadataUpdate } from '../../model/metadataUpdate';
import { HoldUnholdRequest } from '../../model/holdUnholdRequest';
import { messages } from '../messages/message.constant';
import { CustomTreeNode } from '../treeview/CustomTreeNode';
import { DocumentTreeRequest } from '../../model/documentTreeRequest';
import { Year } from '../../model/year';
import { FormControl } from '@angular/forms';
import { GraphQLService } from '../services/graphQL.service';
import { CheckInCheckOutRequest } from '../../model/CheckInCheckOutRequest';
import { HoldUnholdDocument } from '../../model/holdUnholdDocument';

@Component({
  selector: 'app-docmanagement',
  templateUrl: './docmanagement.component.html',
  styleUrls: ['./docmanagement.component.css'],
  providers: [MessageService],
})
export class DocmanagementComponent {

  activeAccount!: any;
  username!: string;
  userId!: string;
  userRole!: string[];
  isSuperUser: boolean = false;
  isTQRMUser: boolean = false;
  taxpayerLevelDocumentTypeGroup = "";
  taxpayerLevelDocumentTypePending = "";
  taxpayerLevelDocumentTypePermanent = "";

  doctypedropdowndisabled = true;
  doctypegroupdropdowndisabled = true;
  docdescriptiondropdowndisabled = true;
  workflowdropdowndisabled = true;
  taxyeardisabled = true;
  isattaxyearlevel = false;
  isatdoctypegroupnode = false;
  isatdoctypenode = false;
  secdescriptiondropdowndisabled = true;
  isUploadDisabled = true;
  uploadSidebarDisabled = false;
  isUploading = false;
  isUpdating = false;
  files: File[] = [];
  filesUploaded = 0;
  signoffdisabled = true;
  removesignoffdisabled = true;
  signoffSidebarVisible = false;
  removeSignoffSidebarVisible = false;

  deletedisabled = true;
  recoverDisabled = false;

  editDisabled = true;
  IsEditSidebarVisible = false;

  showFiller = false;
  fileUploadSidebarVisible: boolean = false;
  uploadedFiles: any[] = [];
  isSecondaryDescriptionRequired: boolean = false;
  isEditSecondaryDescriptionRequired: boolean = false;
  docTypeDD: Dropdown[] = [];
  docTypeGroupDD: Dropdown[] = [];
  docDescriptionDD: Dropdown[] = [];
  secDescriptionDD: Dropdown[] = [];

  roleDD: Dropdown[] = [];
  newRoleDD: Dropdown[] = [];
  removeRoleDD: Dropdown[] = [];

  // @ts-ignore: Object is possibly 'null'.
  getMetadata: GetDocumentMetadata = new GetDocumentMetadata();

  doctypeddselectedvalue!: any;
  doctypegroupddselectedvalue!: any;
  docdescriptionddselectedvalue!: any;
  secdescriptionddselectedvalue!: any;

  roleselectedvalue!: any;
  removeRoleSelectedValue!: any;

  sendToGPDisabled = true;
  openInBrowserDisabled = true;
  moreMenuDisabled = true;
  showUserPrompt = false;
  client: string = "";
  clientId: string = "";
  taxpayer: string = "";
  taxpayerId: string = "";
  // year: string | undefined = "";
  engagement: string = "";
  engagementId: string = "";
  workflow: string = "";
  workflowId: string = "";
  docTypeGroup: string = "";
  docTypeGroupId: string = "";
  docType: string = "";
  docTypeId: string = "";

  context: any[] = [];
  applicabletaxyear!: any;
  // taxyear!: any;
  userdefineddescription!: any;
  copymetadata: boolean = false;

  items: MenuItem[] = [];

  breadcumItems: MenuItem[] = [];

  parsedContext!: Context;
  contextValues: any[] = [];

  // these below 2 fields will represent engagement and workflow tax year in UI, dynamic also.
  engagementTaxYear: string | undefined = "";
  workflowTaxYear: string | undefined = "";

  // these below 2 fields will contain original context value of engagement and workflow tax year
  engagementTaxYearDuplicate: string | undefined = "";
  workflowTaxYearDuplicate: string | undefined = "";

  contextType: any[] = [];
  isAtTaxpayerLevel: boolean = false;

  home!: MenuItem;

  drawerValue: boolean = true;

  selectedDocuments: SignOffMatrix[] = [];

  clientValidation: boolean = false;

  openWorkflowPopup: boolean = false;
  workflowDD: Dropdown[] = [];
  workflowselectedvalue: Dropdown = { "name": "", "value": "" };
  workflowdropdownoptionalandreadonly: boolean = false;

  allowDirectDocumentUploadToPYEngagementConfigValue = "";
  selectedEngagementAllowDocumentUploadValue = "";

  fileclicked: boolean = false;

  disableEngagement: boolean = false;

  disableWorkflow: boolean = false;
  invalidOperationReasons: string[] = ['Pending Upload', 'Pending Metadata Update', 'Archival In Progress', 'Unarchival In Progress', 'Hold In Progress', 'Unhold In Progress', 'Error In Upload'];

  options: any[] = [
    { icon: 'pi pi-angle-double-left', value: false },
    { icon: 'pi pi-angle-double-right', value: true },
  ];

  @ViewChild('menu') menu: MenuItem | undefined;
  @ViewChild('fileUpload', { static: false }) fileUpload: FileUpload | undefined;

  dom: HTMLDivElement | undefined;

  tempMetadatas: Documents[] = [];
  selectedFileName!: string;

  years: any = [];
  selectedYears!: Year[];
  selectedYearsForEdit!: Year[];
  selectedTaxYear!: Year;
  selectedTaxYearForEdit!: Year;

  constructor(private messageService: MessageService, private documentsService: documentsService,
    private confirmationService: ConfirmationService, private authService: MsalService,
    private commonService: CommonService, private render: Renderer2,
    private elementRef: ElementRef, private uploadService: uploadService,
    private localService: LocalService, private router: Router,
    private graphQLService: GraphQLService) { }

  ngOnInit() {
    this.getContext();

    this.getConfigurationValuesForTaxpayerLevelDocs();

    this.dom = this.elementRef.nativeElement;
    this.breadcumItems = [];

    this.activeAccount = this.authService?.instance?.getActiveAccount();
    this.username = this.activeAccount?.username;

    this.getUserId(this.username);

    this.newRoleDD = [];

    this.home = { label: this.parsedContext.clientName };

    const currentYear: number = new Date().getFullYear();

    for (let year = currentYear + 5; year >= currentYear - 25; year--) {
      this.years.push({ name: year.toString(), code: year.toString() });
    }

    this.nodeClickedSubscription();
    this.selectedRowsSubscription();
    this.buttonsDisableOnDataLoading();
  }


  getUserId(username: string) {
    this.documentsService.getUserId(username).subscribe({
      next: (response) => {
        this.userId = response;
        this.getUserRole(this.userId);
      },
      error: (error) => {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: messages.errorOccurred });
        console.error(error);
      }
    })
  }

  getUserRole(userid: string) {
    this.documentsService.getUserRole(userid).subscribe({
      next: (response) => {
        this.userRole = response;
        if (this.userRole.includes("BDO Super User")) {
          this.isSuperUser = true;
        }
        else this.isSuperUser = false;
        if (this.userRole.includes("BDO TQRM")) {
          this.isTQRMUser = true;
        }
        else this.isTQRMUser = false;
        this.initialiseMoreMenu();
      },
      error: (error) => {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: messages.errorOccurred });
        console.error(error);
      }
    });
  }

  initialiseMoreMenu() {
    if (this.isSuperUser == true) {
      this.items = [
        {
          label: 'Check-In',
          icon: 'pi pi-sign-in',
          //visible: true,
          command: () => {
            this.checkInDocuments();
          }
        },
        {
          label: 'Check-Out',
          icon: 'pi pi-sign-out',
          command: () => {
            this.checkOutDocuments();
          }

        },
        //{
        //  label: 'Retain Document',
        //  icon: 'pi pi-file-import',
        //  command: () => {

        //  }
        //},
        {
          label: 'Archive',
          icon: 'pi pi-lock',
          command: () => {
            this.archiveDocuments();
          }
        },
        {
          label: 'Unarchive',
          icon: 'pi pi-lock-open',
          command: () => {
            this.unarchiveDocuments();
          }
        },
        {
          label: 'Hold',
          icon: 'pi pi-minus-circle',
          command: () => {
            this.holdDocuments();
          }
        },
        {
          label: 'Unhold',
          icon: 'pi pi-circle-off',
          command: () => {
            this.unholdDocuments();
          }
        },
      ]
    }
    else {
      this.items = [
        {
          label: 'Check-In',
          icon: 'pi pi-sign-in',
          command: () => {
            this.checkInDocuments();
          }
        },
        {
          label: 'Check-Out',
          icon: 'pi pi-sign-out',
          command: () => {
            this.checkOutDocuments();
          }

        },
        //{
        //  label: 'Retain Document',
        //  icon: 'pi pi-file-import',
        //  command: () => {
        //  }
        //},
      ]
    }

  }


  getConfigurationValuesForTaxpayerLevelDocs() {
    this.documentsService.getConfigurationValuesForTaxpayerLevelDocs(Constant[Constant.taxpayerLevelDocumentTypeGroup], Constant[Constant.taxpayerLevelDocumentTypePending], Constant[Constant.taxpayerLevelDocumentTypePermanent]).subscribe({
      next: (response) => {
        if (response.length !== 0) {
          this.taxpayerLevelDocumentTypeGroup = response[0];
          this.taxpayerLevelDocumentTypePending = response[1];
          this.taxpayerLevelDocumentTypePermanent = response[2];
        }
      },
      error: (error) => {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: messages.errorOccurred });
        console.error(error);
      }
    });
  }

  buttonsDisableOnDataLoading() {

    this.commonService.buttonDisableForDataLoadingEvent.subscribe((val: boolean) => {
      if (val == true) {
        this.recoverDisabled = true;
        this.uploadSidebarDisabled = true;
      }
      else if (val == false) {
        this.recoverDisabled = false;
        this.uploadSidebarDisabled = false;
      }
    });
  }

  async buttonsEnableOnRowSelect() {
    let isWorkflowStatusInactive = false;
    if (await this.isWorkflowStatusInactive(this.selectedDocuments)) {
      isWorkflowStatusInactive = true;
    }
    if (this.selectedDocuments.length == 0) {
      this.uploadSidebarDisabled = false;
      this.signoffdisabled = true;
      this.removesignoffdisabled = true;
      this.deletedisabled = true;
      this.editDisabled = true;
      this.sendToGPDisabled = true;
      this.openInBrowserDisabled = true;
      this.moreMenuDisabled = true;
    }
    else if (this.selectedDocuments.length > 1) {
      this.uploadSidebarDisabled = true;
      this.signoffdisabled = true;
      this.removesignoffdisabled = true;
      if (isWorkflowStatusInactive || this.isDeleteDisabled(this.selectedDocuments)) {
        this.deletedisabled = true;
      }
      else {
        this.deletedisabled = false;
      }
      this.editDisabled = true;
      if (isWorkflowStatusInactive || this.isInvalidOperation(this.selectedDocuments)) {
        this.moreMenuDisabled = true;
      }
      else {
        this.moreMenuDisabled = false;
      }
      //if (this.taxpayer && !this.engagement) {
      //  this.sendToGPDisabled = true;
      //}
      if (this.isDocAtTaxpayerLevel(this.selectedDocuments)) {
        this.sendToGPDisabled = true;
      }
      else if (this.isInvalidOperation(this.selectedDocuments)) {
        this.sendToGPDisabled = true;
      }
      else {
        this.sendToGPDisabled = false;
      }
      // NEED TO WRITE WHAT WILL HAPPEN IF USER WANTS TO OPEN MORE THAN 1 DOC USING OPEN IN BROWSER BUTTON, BUT SOME DOCS DONT HAVE URL
      //if (isDocUrlAbsent(this.selectedDocuments)) {
      //  this.openInBrowserDisabled = true;
      //}
      this.openInBrowserDisabled = true;
    }
    else {
      if (!this.selectedDocuments[0]?.url) {
        this.signoffdisabled = true;
        this.removesignoffdisabled = true;
        this.editDisabled = true;
        this.openInBrowserDisabled = true;
      }
      else {
        this.signoffdisabled = false;
        this.removesignoffdisabled = false;
        this.editDisabled = false;
        this.openInBrowserDisabled = false;
      }
      if (isWorkflowStatusInactive) {
        this.signoffdisabled = true;
        this.removesignoffdisabled = true;
      }
      if (isWorkflowStatusInactive || this.selectedDocuments[0]?.statuscode == 'Archived' || this.isInvalidOperation(this.selectedDocuments)) {
        this.editDisabled = true;
      }
      else if (this.selectedDocuments[0]?.statuscode == 'Unarchived' && this.isInvalidOperation(this.selectedDocuments) === false) {
        this.editDisabled = false;
      }

      if (isWorkflowStatusInactive || this.isDeleteDisabled(this.selectedDocuments)) {
        this.deletedisabled = true;
      }
      else {
        this.deletedisabled = false;
      }
      this.uploadSidebarDisabled = true;
      //if (this.taxpayer && !this.engagement) {
      //  this.sendToGPDisabled = true;
      //}
      if (this.selectedDocuments[0].taxpayer && !this.selectedDocuments[0].engagement) {
        this.sendToGPDisabled = true;
      }
      else if (this.isInvalidOperation(this.selectedDocuments)) {
        this.sendToGPDisabled = true;
      }
      else {
        this.sendToGPDisabled = false;
      }
      if (isWorkflowStatusInactive || this.isInvalidOperation(this.selectedDocuments)) {
        this.moreMenuDisabled = true;
      }
      else {
        this.moreMenuDisabled = false;
      }
    }
  }

  isInvalidOperation(selectedDocs: any): boolean {
    for (let doc of selectedDocs) {
      if (doc.url === null || doc.url === '' || this.invalidOperationReasons.includes(doc.statuscode)) {
        return true;
      }
    }
    return false;
  }

  isDocAtTaxpayerLevel(selectedDocs: any): boolean {
    for (let doc of selectedDocs) {
      if (doc.taxpayer && !doc.engagement) {
        return true;
      }
    }
    return false;
  }

  isStatusArchived(selectedDocs: any): boolean {
    for (let doc of selectedDocs) {
      if (doc.statuscode === 'Archived') {
        return true;
      }
    }
    return false;
  }

  isCheckedOut(selectedDocs: any): boolean {
    for (let doc of selectedDocs) {
      if (doc.doccheckoutstatus === 'Checked Out') {
        return true;
      }
    }
    return false;
  }

  hasRetentionLabel(selectedDocs: any): boolean {
    for (let doc of selectedDocs) {
      if (doc.retentionlabel) {
        return true;
      }
    }
    return false;
  }

  isDeleteDisabled(selectedDocs: any): boolean {
    for (let doc of selectedDocs) {
      if (doc.url === null || doc.url === '' || this.invalidOperationReasons.includes(doc.statuscode)
        || doc.retentionlabel || doc.statuscode === 'Archived' || doc.doccheckoutstatus === 'Checked Out') {
        return true;
      }
    }
    return false;
  }

  async isWorkflowStatusInactive(selectedDocs: any): Promise<boolean> {
    if (selectedDocs.length == 0) { return false; }
    let selecteddocsworkflowstatus: string[] = [];
    let keyListSelectedDocs: string[] = this.selectedDocuments.map(x => x.key);
    let isWorkflowStatusInactive = false;
    try {
      selecteddocsworkflowstatus = await this.documentsService.getWorkflowStatusOfDocuments(keyListSelectedDocs).toPromise();
      isWorkflowStatusInactive = selecteddocsworkflowstatus.includes('Inactive');
    }
    catch (error) {
      console.error(error);
    }
    return isWorkflowStatusInactive;
  }

  //CONFIRM
  //signoffEnableOnNodeSelect(datalength: number, workflowlength: number) {
  //  if (workflowlength != -1 && datalength >= workflowlength) {
  //    this.signoffdisabled = false;
  //  }
  //  else {
  //    this.signoffdisabled = true;
  //  }
  //}


  private nodeClickedSubscription() {
    this.commonService.nodeClickedEvent.subscribe(async (data: CustomTreeNode[]) => {

      let selectedNode = data.length > 0 ? data[0] || null : null;

      this.validateSelectedRow(selectedNode);

      let isExists = data.some(x => x.label === "Year Not Found - Engagement");

      if (isExists === true) {
        this.clientValidation = false;
      }

      let items: MenuItem[] | { label: string; }[] = [];
      let node;
      for (let i = data.length - 1; i >= 0; i--) {
        node = data[i];

        if (node?.label) {
          items.push({ label: node?.label?.replace(' - Workflow', '').replace(' - Engagement', ''), id: node.key });
          if (node.type == "client" && node.label == this.parsedContext.clientName) {
            this.home = { label: this.parsedContext.clientName, id: node.key };
          }
          this.context.push(node?.fullLabel?.replace(' - Workflow', '').replace(' - Engagement', ''));
          this.contextValues.push(node.recordId);
          this.contextType.push(node.type);
          //if (node.workflowTaxYear !== null) {
          //  this.workflowTaxYear = node.workflowTaxYear;
          //}
        }
        else {
          this.context.push("");
          this.contextValues.push("");
          this.contextType.push("");
        }
      }

      console.log("label" + "\t" + this.context + "types:" + "\t" + this.contextType);

      items.splice(0, 1);
      this.breadcumItems = items;

      let clientindex = this.contextType.indexOf('client') ?? 0;
      let taxpayerindex = this.contextType.indexOf('taxpayer') ?? 0;
      let engagementtaxyearindex = this.contextType.indexOf('engagementtaxyear') ?? 0;
      let workflowtaxyearindex = this.contextType.indexOf('workflowtaxyear') ?? 0;
      let engagementindex = this.contextType.indexOf('engagement') ?? 0;
      let workflowindex = this.contextType.indexOf('workflow') ?? 0;
      let documentTypeGroupIndex = this.contextType.indexOf('doctypegroup') ?? 0;
      let documentTypeIndex = this.contextType.indexOf('doctype') ?? 0;

      let workflowlength = -1;
      if (workflowindex != -1) {
        workflowlength = workflowindex;
      }

      //CONFIRM
      //this.signoffEnableOnNodeSelect(data.length, workflowlength);

      this.client = this.context[clientindex];
      this.clientId = this.contextValues[clientindex];
      this.taxpayer = this.context[taxpayerindex];
      this.taxpayerId = this.contextValues[taxpayerindex];
      //if (this.workflowTaxYear) {
      //  this.year = this.workflowTaxYear;
      //}
      //else {
      //  this.year = this.context[engagementtaxyearindex];
      //}
      this.engagementTaxYear = this.context[engagementtaxyearindex];
      this.engagementTaxYearDuplicate = this.engagementTaxYear;
      this.workflowTaxYear = this.context[workflowtaxyearindex];
      this.workflowTaxYearDuplicate = this.workflowTaxYear;
      this.engagement = this.context[engagementindex];
      this.engagementId = this.contextValues[engagementindex];
      this.workflow = this.context[workflowindex];
      this.workflowId = this.contextValues[workflowindex];
      this.docTypeGroup = this.context[documentTypeGroupIndex];
      this.docTypeGroupId = this.contextValues[documentTypeGroupIndex];
      this.docType = this.context[documentTypeIndex];
      this.docTypeId = this.contextValues[documentTypeIndex];
      this.contextType = [];
      this.context = [];
      this.contextValues = [];
      // this.workflowTaxYear = "";

      const request: DocumentTreeRequest = {
        clientId: this.clientId === undefined ? "" : this.clientId,
        taxpayerId: this.taxpayerId === undefined ? "" : this.taxpayerId,
        engagementtaxyear: this.engagementTaxYear === undefined ? "" : this.engagementTaxYear,
        workflowtaxyear: this.workflowTaxYear === undefined ? "" : this.workflowTaxYear,
        engagementId: this.engagementId === undefined ? "" : this.engagementId,
        workflowId: this.workflowId === undefined ? "" : this.workflowId,
        docTypeGroupId: this.docTypeGroupId === undefined ? "" : this.docTypeGroupId,
        docTypeId: this.docTypeId === undefined ? "" : this.docTypeId
      };

      this.commonService.getDocumentTreeOnNodeClick(request);

      if (!this.engagement && !this.workflow) {
        this.openWorkflowPopup = false;
        this.uploadValidation();
        this.workflowDD = [];
      }

      if (this.engagement && this.workflow) {
        this.openWorkflowPopup = false;
        this.uploadValidation();
      }

      if (this.engagement && !this.workflow) {
        if (this.fileclicked) {
          this.openWorkflowPopup = true;
          await this.getAllowDirectDocumentUploadToPYEngagementConfigValue();
          await this.getSelectedEngagementAllowDocumentUploadValue(this.engagementId);
          if ((this.allowDirectDocumentUploadToPYEngagementConfigValue == "Yes") && (this.selectedEngagementAllowDocumentUploadValue == "Yes")) {
            this.workflowdropdownoptionalandreadonly = true;
          }
          else {
            this.workflowdropdownoptionalandreadonly = false;
          }
          this.uploadValidation();
        }
        if (this.engagementId) {
          this.documentsService.getMetadata(this.engagementId, Entities[Entities.msdyn_project], this.clientId).subscribe({
            next: (response) => {
              console.log(response);
              let sortedresponse = response?.sort((a: any, b: any) => a.name.localeCompare(b.name));
              this.workflowDD = sortedresponse;
            },
            error: (error) => {
              this.messageService.add({ severity: 'error', summary: 'Error', detail: messages.failedFetch });
              console.error(error);
            }
          });
        }
      }

      if (this.selectedDocuments.length != 0) {
        let keyListSelectedDocs: string[] = this.selectedDocuments.map(x => x.key);
        let parameters = new HttpParams();
        parameters = parameters.append('keys', keyListSelectedDocs.join(','));
        parameters = parameters.append('userid', this.userId);
        parameters = parameters.append('clientId', this.clientId);
        this.documentsService.getRoles(parameters).subscribe({
          next: (response) => {
            let sortedresponse = response?.sort((a: any, b: any) => a.name.localeCompare(b.name));
            this.roleDD = sortedresponse;
            this.setNewRoleDD(this.roleDD);
          },
          error: (error) => {
            this.messageService.add({ severity: 'error', summary: 'Error', detail: messages.failedFetch });
            console.error(error);
          }
        });
      }
      else {
        this.signoffdisabled = true;
        this.removesignoffdisabled = true;
        this.getSignOffTooltip();
      }


      if (this.client && !this.taxpayer && !this.engagement && !this.workflow) {
        this.uploadValidation();
      }

    });
  }

  validateSelectedRow(selectedNode: TreeNode<any> | null) {
    if (selectedNode) {
      if (selectedNode?.type == TreeTypes[TreeTypes.client]) {
        this.clientValidation = false;
      }
      else if (selectedNode?.type == TreeTypes[TreeTypes.engagementtaxyear]) {
        this.clientValidation = false;
      }
      else if (selectedNode?.type == TreeTypes[TreeTypes.workflowtaxyear]) {
        this.clientValidation = false;
      }
      else {
        this.clientValidation = true;
      }
    }
  }

  private getContext() {
    let context = this.localService.getData(Constant[Constant.context]);
    this.parsedContext = JSON.parse(context ?? '');
  }


  breadcrumbItemClicked(event: any) {
    console.log(event.item);
    this.commonService.breadcrumbClicked(event.item.id);
  }

  signoffRows() {
    let keyListSelectedDocs: string[] = this.selectedDocuments.map(x => x.key);
    const data: signOffDTO = new signOffDTO();
    data.username = this.userId;
    data.role = this.roleselectedvalue.value;
    data.selectedDocuments = keyListSelectedDocs;
    this.documentsService.postRole(data)
      .subscribe({
        next: (response) => {
          console.log(response);
          this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Signed Off successfully' });
          this.roleselectedvalue = "";
          this.commonService.refreshOnOperationComplete(true);
          this.signoffSidebarVisible = false;
          this.onCloseSidebar('signoff');
        },
        error: (error) => {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: messages.failedSignOff });
          console.error(error);
        }
      });
  }

  removeSignoffRows() {
    let keyListSelectedDocs: string[] = this.selectedDocuments.map(x => x.key);
    const data: signOffDTO = new signOffDTO();
    data.username = this.userId;
    data.role = this.removeRoleSelectedValue.value;
    data.selectedDocuments = keyListSelectedDocs;
    this.documentsService.postRemoveRole(data)
      .subscribe({
        next: (response) => {
          console.log(response);
          this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Removed signed off successfully' });
          this.removeRoleSelectedValue = "";
          this.commonService.refreshOnOperationComplete(true);
          this.removeSignoffSidebarVisible = false;
          this.onCloseSidebar('removesignoff');
        },
        error: (error) => {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: messages.failedRemoveSignOff });
          console.error(error);
        }
      });
  }

  sendToGlobalPortal() {
    if (this.sendToGPDisabled) {
      return;
    }
    this.documentsService.SendToGlobalPortal(this.selectedDocuments).subscribe({
      next: (response) => {
        console.log(response);
        let message = "Document sending to Global Portal. You can navigate away from this page. The file will copy in the background and will show the Global Portal Sent Date when complete.";
        this.messageService.add({ severity: 'success', summary: 'Success', detail: message, life: 10000 });
      },
      error: (error) => {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: messages.errorOccurred });
        console.error(error);
      }
    })
  }

  openSignoffPanel() {

    this.roleDD = [];
    this.newRoleDD = [];
    this.roleselectedvalue = "";

    if (this.signoffdisabled) {
      return;
    }

    this.signoffSidebarVisible = true;
    this.commonService.disableRowSelectOnPanel(true);
    let keylistselecteddocs: string[] = this.selectedDocuments.map(x => x.key);
    console.log(keylistselecteddocs);

    let parameters = new HttpParams();
    parameters = parameters.append('keys', keylistselecteddocs.join(','));
    parameters = parameters.append('userid', this.userId);
    parameters = parameters.append('clientid', this.clientId);
    this.documentsService.getRoles(parameters).subscribe({
      next: (response) => {
        this.roleDD = response;
        this.setNewRoleDD(this.roleDD);
      },
      error: (error) => {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: messages.failedFetch });
        console.error(error);
      }
    });
  }

  openRemoveSignoffPanel() {
    if (this.removesignoffdisabled) {
      return;
    }

    this.removeSignoffSidebarVisible = true;
    this.commonService.disableRowSelectOnPanel(true);
    let keylistselecteddocs: string[] = this.selectedDocuments.map(x => x.key);
    console.log(keylistselecteddocs);

    let parameters = new HttpParams();
    parameters = parameters.append('keys', keylistselecteddocs.join(','));
    parameters = parameters.append('userid', this.userId);
    parameters = parameters.append('clientid', this.clientId);
    this.documentsService.getAssignedRoles(parameters).subscribe({
      next: (response) => {
        this.removeRoleDD = response;
      },
      error: (error) => {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: messages.failedFetch });
        console.error(error);
      }
    });
  }

  setNewRoleDD(roleDD: Dropdown[]) {
    // COMMENTING THE ROLES WHICH ARE NOT THERE ON THE SIGNOFF MATRIX
    for (let dd of roleDD) {
      //if (dd.name == 'Billing Authority') {
      //  let element = { name: 'Billing Authority', value: 'mcs_billingauthority' };
      //  this.newRoleDD.push(element);
      //}
      //else if (dd.name == 'Billing Specialist') {
      //  let element = { name: 'Billing Specialist', value: 'mcs_billingspecialist' };
      //  this.newRoleDD.push(element);
      //}
      //else if (dd.name == 'Contract Authority') {
      //  let element = { name: 'Contract Authority', value: 'mcs_contractauthority' };
      //  this.newRoleDD.push(element);
      //}
      //else if (dd.name == 'Contract Line Authority') {
      //  let element = { name: 'Contract Line Authority', value: 'mcs_contractlineauthority' };
      //  this.newRoleDD.push(element);
      //}
      /*else*/ if (dd.name == 'Detail Reviewer') {
        let element = { name: 'Detail Reviewer', value: 'mcs_detailreviewer' };
        this.newRoleDD.push(element);
      }
      //else if (dd.name == 'Engagement Billing Specialist') {
      //  let element = { name: 'Engagement Billing Specialist', value: 'mcs_engagementbillingspecialist' };
      //  this.newRoleDD.push(element);
      //}
      else if (dd.name == 'Office Admin') {
        let element = { name: 'Office Admin', value: 'mcs_officeadmin' };
        this.newRoleDD.push(element);
      }
      //else if (dd.name == 'Partner In Charge') {
      //  let element = { name: 'Partner In Charge', value: 'mcs_partnerincharge' };
      //  this.newRoleDD.push(element);
      //}
      else if (dd.name == 'Preparer') {
        let element = { name: 'Preparer', value: 'mcs_preparer' };
        this.newRoleDD.push(element);
      }
      //else if (dd.name == 'Project Authority') {
      //  let element = { name: 'Project Authority', value: 'mcs_projectauthority' };
      //  this.newRoleDD.push(element);
      //}
      //else if (dd.name == 'Project Manager') {
      //  let element = { name: 'Project Manager', value: 'mcs_projectmanager' };
      //  this.newRoleDD.push(element);
      //}
      else if (dd.name == 'Required Reviewer') {
        let element = { name: 'Required Reviewer', value: 'mcs_requiredreviewer' };
        this.newRoleDD.push(element);
      }
      else if (dd.name == 'Signer') {
        let element = { name: 'Signer', value: 'mcs_signer' };
        this.newRoleDD.push(element);
      }
      //else if (dd.name == 'Specialist Reviewer') {
      //  let element = { name: 'Specialist Reviewer', value: 'mcs_specialistreviewer' };
      //  this.newRoleDD.push(element);
      //}
      else if (dd.name == 'Substantive Reviewer') {
        let element = { name: 'Substantive Reviewer', value: 'mcs_substantivereviewer' };
        this.newRoleDD.push(element);
      }
      //else if (dd.name == 'Team_Member') {
      //  let element = { name: 'Team_Member', value: 'mcs_teammember' };
      //  this.newRoleDD.push(element);
      //}
      else if (dd.name == 'Workflow Project Manager') {
        let element = { name: 'Project Manager', value: 'mcs_projectmanager'/*'mcs_workflowprojectmanager'*/ };
        this.newRoleDD.push(element);
      }
    }
  }


  onCloseSidebar(buttonClicked: string) {
    this.commonService.disableRowSelectOnPanel(false);
    if (buttonClicked == 'upload') {
      this.openWorkflowPopup = false;
      this.fileclicked = false;
      this.doctypedropdowndisabled = true;
      this.doctypegroupdropdowndisabled = true;
      this.docdescriptiondropdowndisabled = true;
      this.secdescriptiondropdowndisabled = true;
      this.taxyeardisabled = true;
      this.isatdoctypegroupnode = false;
      this.isatdoctypenode = false;
      this.isattaxyearlevel = false;
      this.workflowselectedvalue = new Dropdown;
      this.doctypegroupddselectedvalue = "";
      this.doctypeddselectedvalue = "";
      this.docdescriptionddselectedvalue = "";
      this.secdescriptionddselectedvalue = "";
      this.userdefineddescription = "";
      this.applicabletaxyear = "";
      this.selectedYears = [];
      this.selectedYearsForEdit = [];
      // this.taxyear = "";
      this.engagementTaxYear = "";
      this.workflowTaxYear = "";
      if (this.engagementTaxYearDuplicate) {
        this.engagementTaxYear = this.engagementTaxYearDuplicate;
      }
      if (this.workflowTaxYearDuplicate) {
        this.workflowTaxYear = this.workflowTaxYearDuplicate;
      }
      this.selectedTaxYear = { name: "", code: "" };
      this.isSecondaryDescriptionRequired = false;
      this.isUploadDisabled = true;
      this.copymetadata = false;
      this.uploadedFiles = [];
      this.tempMetadatas = [];
      this.fileUpload!.clear();
    }
    else if (buttonClicked == 'edit') {
      this.getMetadata.taxpayers = [];
      this.getMetadata.taxpayerId = "";
      this.getMetadata.selectedTaxpayer = undefined;
      this.getMetadata.engagements = [];
      this.getMetadata.engagementId = "";
      this.getMetadata.selectedEngagement = undefined;
      this.getMetadata.workflows = [];
      this.getMetadata.workflowId = "";
      this.getMetadata.selectedWorkflow = undefined;
      this.getMetadata.doctypes = [];
      this.getMetadata.doctypeId = "";
      this.getMetadata.selectedDoctype = undefined;
      this.getMetadata.doctypeGroups = [];
      this.getMetadata.doctypeGroupId = "";
      this.getMetadata.selectedDoctypeGroup = undefined;
      this.getMetadata.doctypeDescs = [];
      this.getMetadata.doctypeDescId = "";
      this.getMetadata.selectedDoctypeDesc = undefined;
      this.getMetadata.doctype2ndDescs = [];
      this.getMetadata.doctype2ndDescId = "";
      this.getMetadata.selectedDoctype2ndDesc = undefined;
      this.getMetadata.userDefineDescription = "";
      this.getMetadata.applicableTaxYear = "";
      this.getMetadata.taxYear = "";
      this.isEditSecondaryDescriptionRequired = false;
    }
    else if (buttonClicked == 'signoff') {
      this.newRoleDD = [];
      this.roleselectedvalue = "";
    }
    else if (buttonClicked == 'removesignoff') {
      this.removeRoleDD = [];
      this.removeRoleSelectedValue = "";
    }
  }

  opneSideBar() {
    this.fileUploadSidebarVisible = true;
    this.commonService.disableRowSelectOnPanel(true);
    this.getContext();
    if (this.client && this.taxpayer && !this.engagement && !this.workflow) {
      this.isAtTaxpayerLevel = true;
    }
    else {
      this.isAtTaxpayerLevel = false;
    }
    this.documentsService.getDocTypeGroup(this.engagementId).subscribe({
      next: (response) => {
        console.log(response);
        let sortedresponse = response?.sort((a: any, b: any) => a.name.localeCompare(b.name));
        this.docTypeGroupDD = sortedresponse;
      },
      error: (error) => {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: messages.failedFetch });
        console.error(error);
      }
    });;
  }



  drawerHandler(event: any) {
    console.log(event);
    this.commonService.drawerClicked(event.value);
    if (this.drawerValue == true) {
      this.render.addClass(this.menu, "p-menuCustom");
    }
    this.render.addClass(this.menu, "p-menu");
  }

  onSelectFile(event: any) {
    console.log(event);
    const regex = /^(?:\.lock|CON|PRN|AUX|NUL|COM[0-9]|LPT[0-9]|_vti_|desktop\.ini)$|["*:<>?\/\\|#%]|_vti_/i;
    if (event.files.length + this.uploadedFiles.length <= 10) {
      for (let file of event.files) {
        if (file.size <= 2.0000E+9) {
          this.uploadedFiles.push(file);
        } else {
          this.messageService.add({ severity: 'error', summary: messages.fileUpload, detail: `${file.name}` + messages.uploadSizeExceeded });
          continue;
        }

        // Validation for special characters

        if (!regex.test(file.name) == true) {
          continue;
        }
        else {
          this.messageService.add({ severity: 'error', summary: messages.fileUpload, detail: `${file.name}` + messages.invalidFilename });
        }
      }
      this.uploadValidation();
    }
    else {
      this.fileUpload!.clear();
      this.messageService.add({ severity: 'error', summary: messages.fileUpload, detail: messages.uploadfileCountExceeded });
    }
  }

  onCancel(event: any) {
    this.uploadedFiles = [];
    this.tempMetadatas = [];
    this.fileUpload!.clear();
    this.workflowdropdowndisabled = true;
    this.workflowselectedvalue = new Dropdown();
    this.doctypeddselectedvalue = null;
    this.doctypegroupddselectedvalue = null;
    this.docdescriptionddselectedvalue = null;
    this.secdescriptionddselectedvalue = null;
    this.applicabletaxyear = null;
    this.selectedYears = [];
    // this.taxyear = "";
    this.engagementTaxYear = "";
    this.workflowTaxYear = "";
    if (this.engagementTaxYearDuplicate) {
      this.engagementTaxYear = this.engagementTaxYearDuplicate;
    }
    if (this.workflowTaxYearDuplicate) {
      this.workflowTaxYear = this.workflowTaxYearDuplicate;
    }
    this.selectedTaxYear = { name: "", code: "" };
    this.userdefineddescription = null;
    this.uploadValidation();
    this.doctypedropdowndisabled = true;
    this.doctypegroupdropdowndisabled = true;
    this.docdescriptiondropdowndisabled = true;
    this.secdescriptiondropdowndisabled = true;
    this.taxyeardisabled = true;
    this.isUploadDisabled = true;
    this.copymetadata = false;
    this.isatdoctypegroupnode = false;
    this.isatdoctypenode = false;
    this.isattaxyearlevel = false;
  }


  async getAllowDirectDocumentUploadToPYEngagementConfigValue(): Promise<void> {
    this.allowDirectDocumentUploadToPYEngagementConfigValue = await this.documentsService.getAllowDirectDocumentUploadToPYEngagementConfigValue().toPromise();
  }
  async getSelectedEngagementAllowDocumentUploadValue(engagementId: any): Promise<void> {
    this.selectedEngagementAllowDocumentUploadValue = await this.documentsService.getSelectedEngagementAllowDocumentUploadValue(engagementId).toPromise();
  }


  async onfileclick(event: any) {
    this.fileclicked = true;

    this.selectedFileName = event.innerText.trim();

    let files = this.dom?.querySelectorAll('.files');

    this.dom?.querySelectorAll('.files').forEach(
      file => {
        file.classList.remove('focus');
      }
    )

    event.classList.add('focus');

    if (this.engagement && !this.workflow) {
      this.openWorkflowPopup = true;
      this.isAtTaxpayerLevel = false;
      await this.getAllowDirectDocumentUploadToPYEngagementConfigValue();
      await this.getSelectedEngagementAllowDocumentUploadValue(this.engagementId);
      if ((this.allowDirectDocumentUploadToPYEngagementConfigValue == "Yes") && (this.selectedEngagementAllowDocumentUploadValue == "Yes")) {
        this.workflowdropdownoptionalandreadonly = true;
      }
      else {
        this.workflowdropdownoptionalandreadonly = false;
      }
      this.uploadValidation();
    }

    if (!this.engagement && !this.workflow) {
      this.openWorkflowPopup = false;
      this.isAtTaxpayerLevel = true;
      this.uploadValidation();
    }

    if (this.engagement && this.workflow) {
      this.openWorkflowPopup = false;
      this.isAtTaxpayerLevel = false;
      this.uploadValidation();
    }

    if (this.client && !this.taxpayer && !this.engagement && this.workflow) {
      this.uploadValidation();
      this.isAtTaxpayerLevel = false;
    }

    if (this.workflowdropdownoptionalandreadonly == true) {
      this.workflowdropdowndisabled = true;
    }
    else {
      this.workflowdropdowndisabled = false;
    }
    this.doctypedropdowndisabled = false;
    this.doctypegroupdropdowndisabled = false;
    this.docdescriptiondropdowndisabled = false;
    this.secdescriptiondropdowndisabled = false;
    this.isUploadDisabled = false;
    this.taxyeardisabled = false;

    if (this.selectedFileName) {
      let metadata = this.tempMetadatas.find(x => x.mcsName === this.selectedFileName);
      if (metadata) {
        this.applicabletaxyear = metadata.mcsApplicableTaxYear;
        let yearList = this.applicabletaxyear?.split(',');
        this.selectedYears = this.years?.filter((year: any) => { return yearList?.includes(year.name); })
        this.engagementTaxYear = metadata.mcsEngagementTaxYear;
        this.workflowTaxYear = metadata.mcsWorkflowTaxYear;
        this.selectedTaxYear = { name: metadata.selectedTaxYear ?? "", code: metadata.selectedTaxYear ?? "" };
        if (this.openWorkflowPopup) {
          this.workflowselectedvalue = metadata?.workflowDD?.find(option => option?.value === metadata?.mcsHierarchyWorkflowId) ?? { name: "", value: "" };
        }
        this.userdefineddescription = metadata.mcsUserDefinedDescription;
        this.docTypeGroupDD = metadata.mcsDoctypeGroupDD!;
        this.docTypeDD = metadata.mcsDoctypeDD!;
        this.docDescriptionDD = metadata.mcsDoctypeDescDD!;
        this.secDescriptionDD = metadata.mcsDoctype2ndDescDD!;
        setTimeout(() => {
          this.doctypegroupddselectedvalue = metadata?.mcsDoctypeGroupDD?.find(option => option?.value === metadata?.mcsDoctypeGroup?.value);
          this.doctypeddselectedvalue = metadata?.mcsDoctypeDD?.find(option => option?.value === metadata?.mcsDoctype?.value);
          this.docdescriptionddselectedvalue = metadata?.mcsDoctypeDescDD?.find(option => option?.value === metadata?.mcsDoctypeDesc?.value);
          this.secdescriptionddselectedvalue = metadata?.mcsDoctype2ndDescDD?.find(option => option?.value === metadata?.mcsDoctype2ndDesc?.value);
        }, 300);
      }
      else {
        this.applicabletaxyear = null;
        this.selectedYears = [];
        // HOW TO PROCEED EG - MULTI UPLOAD ENGAGEMENT LEVEL, WE HAVE TO CLEAR WORKFLOWTAXYEAR VALUE FROM UI? -done
        if (!this.engagementTaxYearDuplicate) {
          this.engagementTaxYear = "";
        }
        if (!this.workflowTaxYearDuplicate) {
          this.workflowTaxYear = "";
        }
        this.selectedTaxYear = { name: "", code: "" };
        this.userdefineddescription = null;
        this.workflowselectedvalue = { name: "", value: "" };
        this.doctypegroupddselectedvalue = null;
        this.doctypeddselectedvalue = null;
        this.docdescriptionddselectedvalue = null;
        this.secdescriptionddselectedvalue = null;

        let document = new Documents();
        document.mcsName = this.selectedFileName;
        document.mcsHierarchyClientId = this.clientId;
        document.mcsHierarchyEngagementId = this.engagementId;
        document.mcsHierarchyTaxpayerId = this.taxpayerId;
        document.mcsHierarchyWorkflowId = this.workflowId;
        document.mcsOwner = this.parsedContext.owner;

        // ADD SELECTEDTAXYEAR TO DOCUMENTS SO THAT IT CAN BE SAVED

        //if (this.engagementTaxYear) {
        //  this.isattaxyearlevel = true;
        //  // document.mcsEngagementTaxYear = this.taxyear = this.year;
        //  document.mcsEngagementTaxYear = this.engagementTaxYear;
        //  // this.selectedTaxYear = { name: this.engagementTaxYear, code: this.engagementTaxYear };
        //}
        //else {
        //  this.engagementTaxYear = this.selectedTaxYear.name;
        //  document.mcsEngagementTaxYear = this.engagementTaxYear;
        //}

        //if (this.workflowTaxYear) {
        //  document.mcsWorkflowTaxYear = this.workflowTaxYear;
        //}
        //else {
        //  this.workflowTaxYear = this.selectedTaxYear.name;
        //  document.mcsWorkflowTaxYear = this.workflowTaxYear;
        //}

        if (this.engagementTaxYear) {
          document.mcsEngagementTaxYear = this.engagementTaxYear;
        }

        if (this.workflowTaxYear) {
          document.mcsWorkflowTaxYear = this.workflowTaxYear;
        }

        if (this.isAtTaxpayerLevel) {
          document.selectedTaxYear = this.selectedTaxYear.name;
        }
        else {
          document.selectedTaxYear = "";
        }

        document.userEmailId = this.username;
        document.mcsDoctypeGroupDD = this.docTypeGroupDD;
        if (this.docTypeGroupId && this.docTypeGroup) {
          this.doctypegroupddselectedvalue = { "name": this.docTypeGroup, "value": this.docTypeGroupId };
        }

        document.mcsDoctypeDD = this.docTypeDD;
        if (this.docTypeId && this.docType) {
          this.doctypeddselectedvalue = { "name": this.docType, "value": this.docTypeId };
        }

        if (this.docTypeGroupId) {
          this.isatdoctypegroupnode = true;
          this.loadDocTypeFromTypeGroup(this.docTypeGroupId)
        }
        if (this.docTypeId) {
          this.isatdoctypenode = true;
          this.loadDocDescriptionFromType(this.docTypeId);
        }

        if (this.doctypegroupddselectedvalue !== undefined) {
          document.mcsDoctypeGroupName = this.doctypegroupddselectedvalue?.name;
          document.mcsDoctypeGroup = this.doctypegroupddselectedvalue;
        }
        if (this.doctypeddselectedvalue !== undefined) {
          document.mcsDoctypeName = this.doctypeddselectedvalue?.name;
          document.mcsDoctype = this.doctypeddselectedvalue;
        }
        if (this.docdescriptionddselectedvalue !== undefined) {
          document.mcsDoctypeDescName = this.docdescriptionddselectedvalue?.name;
          document.mcsDoctypeDesc = this.docdescriptionddselectedvalue?.value;
        }
        if (this.secdescriptionddselectedvalue !== undefined) {
          document.mcsDoctype2ndDescName = this.secdescriptionddselectedvalue?.name;
          document.mcsDoctype2ndDesc = this.secdescriptionddselectedvalue?.value;
        }

        document.mcsApplicableTaxYear = this.applicabletaxyear;
        document.mcsUserDefinedDescription = this.userdefineddescription;

        this.tempMetadatas.push(document);
      }
    }

    this.uploadValidation();
  }


  metadataChanged(event: any, fieldName: string) {

    let metadata = this.tempMetadatas.find(x => x.mcsName === this.selectedFileName);

    if (metadata) {
      if (fieldName === 'taxyear') {
        metadata.selectedTaxYear = this.selectedTaxYear.name;
        metadata.mcsWorkflowTaxYear = this.selectedTaxYear.name;
      }
      else if (fieldName === 'workflow') {
        metadata.mcsHierarchyWorkflowId = event?.value?.value;
        if (this.workflowselectedvalue) {
          //fetch taxyear for selected Workflow and make tax year read-only
          this.documentsService.getWorkflowTaxYear(this.workflowselectedvalue.value).subscribe({
            next: (response) => {

              // this.year = this.taxyear = response;
              this.workflowTaxYear = response;

              // this.selectedTaxYear = { name: this.taxyear.toString(), code: this.taxyear.toString() };
              this.taxyeardisabled = true;
              let metadata = this.tempMetadatas.find(x => x.mcsName === this.selectedFileName);
              if (metadata) {

                if (this.workflowTaxYear) {
                  metadata.mcsWorkflowTaxYear = this.workflowTaxYear;
                }
                //else {
                //  this.engagementTaxYear = this.selectedTaxYear.name;
                //  metadata.mcsEngagementTaxYear = this.engagementTaxYear;
                //}
              }
            },
            error: (error) => {
              this.messageService.add({ severity: 'error', summary: 'Error', detail: messages.failedFetch });
              console.error(error);
            }
          });
          let metadata = this.tempMetadatas.find(x => x.mcsName === this.selectedFileName);
          if (metadata) {
            metadata.workflowDD = this.workflowDD;
            metadata.mcsHierarchyWorkflowId = this.workflowselectedvalue.value;
          }
        }
      }
      else if (fieldName === 'applicationtaxyear') {
        this.applicabletaxyear = this.selectedYears.map(item => item.name).join(',');
        metadata.mcsApplicableTaxYear = this.applicabletaxyear;
      }
      else if (fieldName === 'userdefineddescription') {
        metadata.mcsUserDefinedDescription = event;
      }
      else if (fieldName === 'doctypegroup') {
        this.loadDocTypeFromTypeGroup(event.value.value);
        metadata.mcsDoctypeGroup = event.value;
        metadata.mcsDoctypeGroupName = event.name;
        metadata.mcsDoctype = undefined;
        metadata.mcsDoctypeName = undefined;
        metadata.mcsDoctypeGroupDD = this.docTypeGroupDD;
      }
      else if (fieldName === 'doctype') {
        this.loadDocDescriptionFromType(event.value.value);
        metadata.mcsDoctype = event.value;
        metadata.mcsDoctypeName = event.name;
        metadata.mcsDoctypeDesc = undefined;
        metadata.mcsDoctypeDescName = undefined;
        metadata.mcsDoctypeDD = this.docTypeDD;
        this.docdescriptionddselectedvalue = "";
        this.secdescriptionddselectedvalue = "";
      }
      else if (fieldName === 'docdescription') {
        this.loadSecDescFromDocDesc(event.value.value);
        metadata.mcsDoctypeDesc = event.value;
        metadata.mcsDoctypeDescName = event.name;
        metadata.mcsDoctype2ndDesc = undefined;
        metadata.mcsDoctype2ndDescName = undefined;
        metadata.mcsDoctypeDescDD = this.docDescriptionDD;
        this.secdescriptionddselectedvalue = "";
      }
      else if (fieldName === 'secdescription') {
        metadata.mcsDoctype2ndDesc = event.value;
        metadata.mcsDoctype2ndDescName = event.name;
        metadata.mcsDoctype2ndDescDD = this.secDescriptionDD;
      }

      if (this.copymetadata) {
        this.updateCopiedMetaData(metadata);
      }
    }

    this.uploadValidation();
  }

  metadataChangedForEdit(event: any, fieldName: string) {

    let metadata = new MetadataUpdate();

    if (metadata) {
      if (fieldName === 'taxpayer') {
        metadata.taxpayerId = event.value;
        this.getMetadata.engagements = [];
        this.getMetadata.workflows = [];
        this.getMetadata.doctypeGroups = [];
        this.getMetadata.doctypes = [];
        this.getMetadata.doctypeDescs = [];
        this.getMetadata.doctype2ndDescs = [];
        this.loadEngagementFromTaxpayer(event.value);

      }
      else if (fieldName === 'engagement') {
        metadata.engagementId = event.value;
        this.getMetadata.workflows = [];
        this.getMetadata.doctypeGroups = [];
        this.getMetadata.doctypes = [];
        this.getMetadata.doctypeDescs = [];
        this.getMetadata.doctype2ndDescs = [];
        this.selectedTaxYearForEdit = { name: "", code: "" };
        this.loadWorkflowFromEngagement(event.value);

        this.documentsService.getEngagementTaxYear(event.value).subscribe({
          next: (response) => {
            this.getMetadata.engagementTaxYear = response;
          },
          error: (error) => {
            this.messageService.add({ severity: 'error', summary: 'Error', detail: messages.failedFetch });
            console.error(error);
          }
        });
      }
      else if (fieldName === 'workflow') {
        metadata.workflowId = event.value;
        this.getMetadata.doctypeGroups = [];
        this.getMetadata.doctypes = [];
        this.getMetadata.doctypeDescs = [];
        this.getMetadata.doctype2ndDescs = [];
        this.loadDocTypeGroupFromWorkflow(this.getMetadata.selectedEngagement?.value);

        this.documentsService.getWorkflowTaxYear(event.value).subscribe({
          next: (response) => {
            this.getMetadata.taxYear = response;
            this.selectedTaxYearForEdit = this.years.filter((year: any) => year.name == this.getMetadata.taxYear)[0];
            this.taxyeardisabled = true;
          },
          error: (error) => {
            this.messageService.add({ severity: 'error', summary: 'Error', detail: messages.failedFetch });
            console.error(error);
          }
        });
      }
      else if (fieldName === 'doctypegroup') {
        metadata.doctypeGroupId = event.value;
        this.getMetadata.doctypes = [];
        this.getMetadata.doctypeDescs = [];
        this.getMetadata.doctype2ndDescs = [];
        this.loadDoctypeFromDoctypeGroupForEdit(event.value);
      }
      else if (fieldName === 'doctype') {
        metadata.doctypeId = event.value;
        this.getMetadata.doctypeDescs = [];
        this.getMetadata.doctype2ndDescs = [];
        this.loadDoctypeDescFromDoctypeForEdit(event.value);
      }
      else if (fieldName === 'docdescription') {
        metadata.doctypeDescId = event.value;
        this.getMetadata.doctype2ndDescs = [];
        this.loadDoctype2ndDescFromDoctypeDescForEdit(event.value);
      }
      else if (fieldName === 'secdescription') {
        metadata.doctype2ndDescId = event.value;
      }
      else if (fieldName === 'userdefineddescription') {
        metadata.userDefineDescription = event;
      }
      else if (fieldName === 'applicabletaxyear') {
        metadata.applicableTaxYear = this.selectedYearsForEdit.map(item => item.name).join(',');
        this.getMetadata.applicableTaxYear = metadata.applicableTaxYear;
      }
      else if (fieldName === 'taxyear') {

        // metadata.taxYear = this.selectedTaxYear.name;
        metadata.taxYear = this.selectedTaxYearForEdit.name;
        this.getMetadata.taxYear = metadata.taxYear;
      }
      this.updateValidation();
    }
  }

  // DD for edit
  // use getupdatemetadata new function
  loadEngagementFromTaxpayer(taxpayerId: string) {
    this.documentsService.getUpdateMetadata(taxpayerId, Entities[Entities.mcs_engagement], this.clientId).subscribe({
      next: (response) => {
        let sortedresponse = response?.sort((a: any, b: any) => a.name.localeCompare(b.name));
        this.getMetadata.engagements = sortedresponse;
      },
      error: (error) => {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: messages.failedFetch });
        console.error(error);
      }
    });
  }

  // DD for edit
  loadWorkflowFromEngagement(engagementId: string) {
    this.documentsService.getUpdateMetadata(engagementId, Entities[Entities.msdyn_project], this.clientId).subscribe({
      next: (response) => {
        let sortedresponse = response?.sort((a: any, b: any) => a.name.localeCompare(b.name));
        this.getMetadata.workflows = sortedresponse;
      },
      error: (error) => {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: messages.failedFetch });
        console.error(error);
      }
    });
  }

  // DD for edit
  loadDocTypeGroupFromWorkflow(engagementId: any) {
    this.documentsService.getDocTypeGroup(engagementId).subscribe({
      next: (response) => {
        let sortedresponse = response?.sort((a: any, b: any) => a.name.localeCompare(b.name));
        this.getMetadata.doctypeGroups = sortedresponse;
      },
      error: (error) => {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: messages.failedFetch });
        console.error(error);
      }
    });
  }

  // DD for edit
  loadDoctypeFromDoctypeGroupForEdit(doctypeGroupId: string) {
    this.documentsService.getUpdateMetadata(doctypeGroupId, Entities[Entities.mcs_doctypetodtgmapping], this.clientId).subscribe({
      next: (response) => {
        let sortedresponse = response?.sort((a: any, b: any) => a.name.localeCompare(b.name));
        this.getMetadata.doctypes = sortedresponse;
      },
      error: (error) => {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: messages.failedFetch });
        console.error(error);
      }
    });
  }


  //DD for upload
  loadDoctypeDescFromDoctype(doctypeId: string) {
    this.documentsService.getMetadata(doctypeId, Entities[Entities.mcs_docdesctodttodtgmapping], this.clientId).subscribe({
      next: (response) => {
        let sortedresponse = response?.sort((a: any, b: any) => a.name.localeCompare(b.name));
        this.getMetadata.doctypeDescs = sortedresponse;
      },
      error: (error) => {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: messages.failedFetch });
        console.error(error);
      }
    });
  }

  // DD for edit
  loadDoctypeDescFromDoctypeForEdit(doctypeId: string) {
    this.documentsService.getUpdateMetadata(doctypeId, Entities[Entities.mcs_docdesctodttodtgmapping], this.clientId).subscribe({
      next: (response) => {
        let sortedresponse = response?.sort((a: any, b: any) => a.name.localeCompare(b.name));
        this.getMetadata.doctypeDescs = sortedresponse;
      },
      error: (error) => {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: messages.failedFetch });
        console.error(error);
      }
    });
  }

  // DD for edit
  loadDoctype2ndDescFromDoctypeDescForEdit(doctypeDescId: string) {
    this.documentsService.getUpdateMetadata(doctypeDescId, Entities[Entities.mcs_secdesctoddtodttodtgmapping], this.clientId).subscribe({
      next: (response) => {
        let sortedresponse = response?.sort((a: any, b: any) => a.name.localeCompare(b.name));
        this.getMetadata.doctype2ndDescs = sortedresponse;
      },
      error: (error) => {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: messages.failedFetch });
        console.error(error);
      }
    });
  }

  // DD for upload
  loadSecDescFromDocDesc(guid: string) {
    this.documentsService.getMetadata(guid, Entities[Entities.mcs_secdesctoddtodttodtgmapping], this.clientId).subscribe({
      next: (response) => {
        let sortedresponse = response?.sort((a: any, b: any) => a.name.localeCompare(b.name));
        this.secDescriptionDD = sortedresponse;
        //if (this.secDescriptionDD.length == 0) {
        //  this.isSecondaryDescriptionRequired = false;
        //}
        //else {
        //  this.isSecondaryDescriptionRequired = true;
        //}
      },
      error: (error) => {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: messages.failedFetch });
        console.error(error);
      }
    });
  }

  // DD for upload
  loadDocDescriptionFromType(guid: string) {
    this.documentsService.getMetadata(guid, Entities[Entities.mcs_docdesctodttodtgmapping], this.clientId).subscribe({
      next: (response) => {
        let sortedresponse = response?.sort((a: any, b: any) => a.name.localeCompare(b.name));
        this.docDescriptionDD = sortedresponse;
      },
      error: (error) => {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: messages.failedFetch });
        console.error(error);
      }
    });
  }

  // DD for upload
  loadDocTypeFromTypeGroup(guid: string) {
    this.docdescriptionddselectedvalue = "";
    this.secdescriptionddselectedvalue = "";
    this.documentsService.getMetadata(guid, Entities[Entities.mcs_doctypetodtgmapping], this.clientId).subscribe({
      next: (response) => {
        let sortedresponse = response?.sort((a: any, b: any) => a.name.localeCompare(b.name));

        // CHECK IF UPLOAD IS AT TAXPAYER LEVEL, IF YES, CHECK FOR ASSOCIATED ENGAGEMENT IS THERE, IF YES, REMOVE PENDING
        if (this.taxpayer && !this.engagement && !this.workflow) {
          this.documentsService.checkIfTaxpayerHasAssociatedEngagement(this.taxpayerId).subscribe({
            next: (response) => {
              if (response == true) {
                let newsortedresponse: Dropdown[] = [];
                for (let dropdown of sortedresponse) {
                  if (!dropdown.name.includes("Pending")) {
                    newsortedresponse.push(dropdown);
                  }
                }
                sortedresponse = newsortedresponse;
              }
              this.docTypeDD = sortedresponse;
            },
            error: (error) => {
              this.messageService.add({ severity: 'error', summary: 'Error', detail: messages.errorOccurred });
              console.error(error);
            },
          });
        }
        else {
          this.docTypeDD = sortedresponse;
        }
        let metadata = this.tempMetadatas.find(x => x.mcsName === this.selectedFileName);
        if (metadata) {
          metadata.mcsDoctypeDD = this.docTypeDD;
        }
      },
      error: (error) => {
        //this.messageService.add({ severity: 'error', summary: error.name, detail: error.error });
        this.messageService.add({ severity: 'error', summary: 'Error', detail: messages.failedFetch });
        console.error(error);
      }
    });
  }

  showCopyMetaDataCheckBox(): any {
    return this.uploadedFiles?.length > 1 && this.selectedFileName;
  }

  uploadValidation(): boolean {
    if (!this.tempMetadatas || this.tempMetadatas.length === 0 || this.uploadedFiles.length === 0) {
      return true;
    }

    if (this.tempMetadatas.length !== this.uploadedFiles.length) {
      return true;
    }

    if (this.isUploading) {
      return true;
    }

    if (this.isUploadDisabled) {
      return true;
    }

    if (!this.workflowselectedvalue.value && !this.workflowdropdownoptionalandreadonly && this.openWorkflowPopup) {
      return true;
    }

    if (this.isAtTaxpayerLevel && !this.selectedTaxYear.code) {
      return true;
    }

    if (this.client && !this.taxpayer && !this.engagement && !this.workflow) {
      return true;
    }

    for (const metadata of this.tempMetadatas) {
      if (!metadata.mcsDoctypeGroup || !metadata.mcsDoctype || !metadata.mcsDoctypeDesc) {
        return true;
      }
    }

    return false;
  }

  public uploadChunkedDocument() {
    if (!this.uploadValidation()) {
      this.isUploading = true;
      this.filesUploaded = 0;
      let formDatas: FormData[] = [];

      this.uploadedFiles.forEach((file: File) => {
        let formData = new FormData();
        let metadata = this.tempMetadatas.find(x => x.mcsName === file.name);

        metadata!.mcsDoctypeGroupName = metadata?.mcsDoctypeGroup?.name;
        metadata!.mcsDoctypeName = metadata?.mcsDoctype?.name;
        metadata!.mcsDoctypeDescName = metadata?.mcsDoctypeDesc?.name;
        metadata!.mcsDoctype2ndDescName = metadata?.mcsDoctype2ndDesc?.name;

        metadata!.mcsDoctypeGroup = metadata?.mcsDoctypeGroup?.value;
        metadata!.mcsDoctype = metadata?.mcsDoctype?.value;
        metadata!.mcsDoctypeDesc = metadata?.mcsDoctypeDesc?.value;
        metadata!.mcsDoctype2ndDesc = metadata?.mcsDoctype2ndDesc?.value;

        var documentMetadata = JSON.stringify(metadata);

        if (file.size > 450 * 1024) {
          var chunkSize = 450 * 1024 * 1024;
          var totalChunks = Math.ceil(file.size / chunkSize);
          var currentChunk = 0;
          var documentId = '';

          const uploadNextChunk = () => {
            const start = currentChunk * chunkSize;
            const end = Math.min(start + chunkSize, file.size);
            const chunk = file.slice(start, end);
            let formData = new FormData();
            formData.append("metadata", documentMetadata);
            formData.append("file", new File([chunk], file.name));
            formData.append("isChunked", "1");
            formData.append("currentIndex", currentChunk.toString());
            formData.append("chunkedLength", totalChunks.toString());
            formData.append("documentId", documentId);

            this.uploadService.create(formData).subscribe({
              next: (response) => {
                currentChunk++;
                documentId = response.mcsDocumentId;
                if (currentChunk < totalChunks) {
                  uploadNextChunk();
                }
                else {
                  this.filesUploaded++;
                  if (this.filesUploaded == this.uploadedFiles.length) {
                    this.isUploading = false;
                    this.onCancel(null);
                    this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Saved successfully' });
                    this.commonService.refreshTreeOnOperationComplete(true);
                    this.commonService.refreshOnOperationComplete(true);
                  }
                }
              },
              error: (error) => {
                this.messageService.add({ severity: 'error', summary: 'Error', detail: messages.failedUpload });
                this.isUploading = false;
                console.error(error);
              }
            });
          };

          uploadNextChunk();
        } else {
          formData.append("metadata", documentMetadata);
          formData.append("file", file);
          formData.append("isChunked", "0");
          formData.append("currentIndex", "0");
          formData.append("chunkedLength", "0");
          formData.append("documentId", "");
          formDatas.push(formData);
        }
      });

      const requests = formDatas.map(document =>
        of(document).pipe(
          mergeMap(doc => this.uploadService.create(doc))
        )
      );

      forkJoin(requests).subscribe({
        next: (responses) => {
          console.log(responses);
          this.filesUploaded += requests.length;
          if (this.filesUploaded == this.uploadedFiles.length) {
            this.isUploading = false;
            this.onCancel(null);
            this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Saved successfully' });
            this.commonService.refreshTreeOnOperationComplete(true);
            this.commonService.refreshOnOperationComplete(true);
          }
        },
        error: (error) => {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: messages.failedUpload });
          this.isUploading = false;
          console.error(error);
        }
      });
    }
  }

  private attachMetadeta() {
    //let formDatas: FormData[] = [];

    //this.uploadedFiles.forEach((file: File) => {
    //  let formData = new FormData();
    //  let metadata = this.tempMetadatas.find(x => x.mcsName === file.name);

    //  metadata!.mcsDoctypeGroupName = metadata?.mcsDoctypeGroup?.name;
    //  metadata!.mcsDoctypeName = metadata?.mcsDoctype?.name;
    //  metadata!.mcsDoctypeDescName = metadata?.mcsDoctypeDesc?.name;
    //  metadata!.mcsDoctype2ndDescName = metadata?.mcsDoctype2ndDesc?.name;

    //  metadata!.mcsDoctypeGroup = metadata?.mcsDoctypeGroup?.value;
    //  metadata!.mcsDoctype = metadata?.mcsDoctype?.value;
    //  metadata!.mcsDoctypeDesc = metadata?.mcsDoctypeDesc?.value;
    //  metadata!.mcsDoctype2ndDesc = metadata?.mcsDoctype2ndDesc?.value;

    //  var documentMetadata = JSON.stringify(metadata);

    //  if (file.size >= 150 * 1024 ) {

    //    var chunkSize = 100 * 1024 * 1024;
    //    var fileSize = file.size;
    //    var chunks = Math.ceil(file.size / chunkSize);
    //    var chunk = 0;

    //    console.log('file size..', fileSize);
    //    console.log('chunks...', chunks);

    //    while (chunk < chunks) {
    //      var offset = chunk * chunkSize;
    //      console.log('current chunk..', chunk);
    //      console.log('offset...', chunk * chunkSize);
    //      console.log('file blob from offset...', offset)
    //      let slicedFile = file.slice(offset, offset + chunkSize);
    //      console.log('sliced file...', slicedFile);
    //      formData.append("metadata", documentMetadata);
    //      formData.append("file", new File([slicedFile], file.name));
    //      formData.append("isChunked", "1");
    //      formData.append("currentIndex", chunk.toString());
    //      formData.append("chunkedLength", chunks.toString());
    //      formDatas.push(formData);
    //      chunk++;
    //    }

    //    //let chunkSize = 400000000;
    //    //let chunkLength = Math.round(file.length / chunkSize);

    //    //for (let i = 0; i < chunkLength; i++) {

    //    //}
    //  } else {
    //    formData.append("metadata", documentMetadata);
    //    formData.append("file", file);
    //    formData.append("isChunked", "0");
    //    formData.append("currentIndex", "0");
    //    formData.append("chunkedLength", "0");
    //    formDatas.push(formData);
    //  }
    //});

    //return formDatas;
  }

  updateCopiedMetaData(metadata: any) {
    var remFiles = this.tempMetadatas.filter(x => x.mcsName !== metadata.mcsName);
    remFiles.forEach(x => {
      x.mcsHierarchyClientId = metadata!.mcsHierarchyClientId;
      x.mcsHierarchyEngagementId = metadata!.mcsHierarchyEngagementId;
      x.mcsHierarchyTaxpayerId = metadata!.mcsHierarchyTaxpayerId;
      x.mcsHierarchyWorkflowId = metadata!.mcsHierarchyWorkflowId;
      x.userEmailId = metadata!.userEmailId;

      x.mcsDoctypeGroup = metadata!.mcsDoctypeGroup;
      x.mcsDoctypeGroupName = metadata!.mcsDoctypeGroupName;
      x.mcsDoctypeGroupDD = metadata!.mcsDoctypeGroupDD;

      x.mcsDoctype = metadata!.mcsDoctype;
      x.mcsDoctypeName = metadata!.mcsDoctypeName;
      x.mcsDoctypeDD = metadata!.mcsDoctypeDD

      x.mcsDoctypeDesc = metadata!.mcsDoctypeDesc;
      x.mcsDoctypeDescName = metadata!.mcsDoctypeDescName;
      x.mcsDoctypeDescDD = metadata!.mcsDoctypeDescDD;

      x.mcsDoctype2ndDesc = metadata!.mcsDoctype2ndDesc;
      x.mcsDoctype2ndDescName = metadata!.mcsDoctype2ndDescName;
      x.mcsDoctype2ndDescDD = metadata!.mcsDoctype2ndDescDD;

      // CHECK IF mcsEngagementTaxYear OR mcsEngagementTaxYearDUPLICATE SHOULD BE USED
      x.mcsEngagementTaxYear = metadata!.mcsEngagementTaxYear;
      x.mcsWorkflowTaxYear = metadata!.mcsWorkflowTaxYear;
      // ADD TAXPAYER LEVEL TAXYEAR ALSO
      if (this.isAtTaxpayerLevel) {
        x.selectedTaxYear = metadata!.selectedTaxYear;
      }
      x.mcsApplicableTaxYear = metadata!.mcsApplicableTaxYear;
      x.mcsUserDefinedDescription = metadata!.mcsUserDefinedDescription;
    })
  }

  copyMetadataToUploadDocuments(checked: any) {
    this.copymetadata = checked.returnValue;
    if (this.copymetadata && this.selectedFileName) {
      let metadata = this.tempMetadatas.find(x => x.mcsName === this.selectedFileName);
      if (metadata) {
        var remFiles = this.uploadedFiles.filter(x => !this.tempMetadatas.find(y => y.mcsName === x.name));

        if (remFiles.length > 0) {
          remFiles.forEach(x => {
            let document = new Documents();
            document.mcsName = x.name;
            document.mcsHierarchyClientId = metadata!.mcsHierarchyClientId;
            document.mcsHierarchyEngagementId = metadata!.mcsHierarchyEngagementId;
            document.mcsHierarchyTaxpayerId = metadata!.mcsHierarchyTaxpayerId;
            document.mcsHierarchyWorkflowId = metadata!.mcsHierarchyWorkflowId;
            document.userEmailId = metadata!.userEmailId;

            document.mcsDoctypeGroup = metadata!.mcsDoctypeGroup;
            document.mcsDoctypeGroupName = metadata!.mcsDoctypeGroupName;
            document.mcsDoctypeGroupDD = metadata!.mcsDoctypeGroupDD;

            document.mcsDoctype = metadata!.mcsDoctype;
            document.mcsDoctypeName = metadata!.mcsDoctypeName;
            document.mcsDoctypeDD = metadata!.mcsDoctypeDD

            document.mcsDoctypeDesc = metadata!.mcsDoctypeDesc;
            document.mcsDoctypeDescName = metadata!.mcsDoctypeDescName;
            document.mcsDoctypeDescDD = metadata!.mcsDoctypeDescDD;

            document.mcsDoctype2ndDesc = metadata!.mcsDoctype2ndDesc;
            document.mcsDoctype2ndDescName = metadata!.mcsDoctype2ndDescName;
            document.mcsDoctype2ndDescDD = metadata!.mcsDoctype2ndDescDD;

            document.mcsEngagementTaxYear = metadata!.mcsEngagementTaxYear;
            document.mcsWorkflowTaxYear = metadata!.mcsWorkflowTaxYear;
            // ADD TAXYEAR FOR TAXPAYER DOC
            if (this.isAtTaxpayerLevel) {
              document.selectedTaxYear = metadata!.selectedTaxYear;
            }
            document.mcsApplicableTaxYear = metadata!.mcsApplicableTaxYear;
            document.mcsUserDefinedDescription = metadata!.mcsUserDefinedDescription;

            this.tempMetadatas.push(document!);
          });
        }
        else {
          this.updateCopiedMetaData(metadata);
        }        
      }
    }
  }

  uploadDocuments() {
    this.uploadChunkedDocument();
  }

  selectedRowsSubscription() {
    this.commonService.matrixRowSelectEvent.subscribe((data: SignOffMatrix[]) => {
      this.selectedDocuments = data;
      this.buttonsEnableOnRowSelect();
    });
  }

  onKeypress(event: any) {
    const charCode = (event.which) ? event.which : event.keyCode;
    const input = event.target as HTMLInputElement;
    const inputValue = input.value + event.key;
    if (isNaN(Number(event.key)) || inputValue.length > 4) {
      event.preventDefault();
    }

  }

  getFileClass(fileName: string): string {
    if (fileName.includes('pdf')) {
      return 'pi pi-file-pdf';
    } else if (fileName.includes('excel') || fileName.includes('xlsx')) {
      return 'pi pi-file-excel';
    } else if (fileName.includes('word') || fileName.includes('docx')) {
      return 'pi pi-file-word';
    } else {
      return 'pi pi-file';
    }
  }


  deleteRows() {
    let keyListSelectedDocs: string[] = this.selectedDocuments.map(x => x.key);
    const data: getRolesDTO = new getRolesDTO();
    data.selectedDocuments = keyListSelectedDocs;
    data.username = this.userId;
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete?',
      accept: () => {
        this.documentsService.deleteRows(data)
          .subscribe({
            next: (response) => {
              this.commonService.drawerClicked(true);
              this.drawerValue = true;
              this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Deleted successfully' });
              this.commonService.refreshOnOperationComplete(true);
            },
            error: (error) => {
              this.messageService.add({ severity: 'error', summary: 'Error', detail: messages.failedDelete });
              console.error(error);
            }
          });
      },
      reject: () => {

      }
    });
  }

  archiveDocuments() {
    let keyListSelectedDocs: string[] = this.selectedDocuments.map(x => x.key);
    this.documentsService.archiveRows(keyListSelectedDocs)
      .subscribe({
        next: (response) => {
          this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Archival initiated' });
          this.commonService.refreshOnOperationComplete(true);
        },
        error: (error) => {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: messages.errorOccurred });
          console.error(error);
        }
      });
  }

  unarchiveDocuments() {
    let keyListSelectedDocs: string[] = this.selectedDocuments.map(x => x.key);
    const data: getRolesDTO = new getRolesDTO();
    data.selectedDocuments = keyListSelectedDocs;
    data.username = this.userId;
    this.documentsService.unarchiveRows(data)
      .subscribe({
        next: (response) => {
          this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Unarchival initiated' });
          this.commonService.refreshOnOperationComplete(true);
        },
        error: (error) => {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: messages.errorOccurred });
          console.error(error);
        }
      });
  }

  // Get hold and unhold Document data.
  getDocumentData() {
    let documentData: HoldUnholdDocument[] = [];
    this.selectedDocuments.forEach(x => {
      const documentRequest: HoldUnholdDocument = {
        dynamicsDocumentID: x.key,
        documentURL: x.url
      }
      documentData.push(documentRequest);
    });

    return documentData;
  }

  // Methods to put documents on hold.
  holdDocuments() {
    let isValidDocument = this.selectedDocuments.find(x => x.statuscode == 'Archived');
    if (isValidDocument !== undefined) {
      this.messageService.add({ severity: 'warn', summary: 'Hold Document(s)', detail: messages.archivedValidationError });
      return;
    }

    const request: HoldUnholdRequest = {
      clientId: this.clientId,
      documents: this.getDocumentData()
    };

    this.documentsService.holdDocuments(request).subscribe({
      next: (response) => {
        this.messageService.add({ severity: 'info', summary: messages.holdDocuments, detail: messages.holdDocumentsTriggered });
        this.commonService.refreshOnOperationComplete(true);
      }, error: (error) => {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: messages.errorOccurred });
        console.error(error);
        //this.messageService.add({ severity: 'error', summary: messages.holdDocuments, detail: error });
      }
    });
  }

  // Method to remove documents from hold.
  unholdDocuments() {
    let isValidDocument = this.selectedDocuments.find(x => x.statuscode == 'Archived');
    if (isValidDocument !== undefined) {
      this.messageService.add({ severity: 'warn', summary: 'Unhold Document(s)', detail: messages.archivedValidationError });
      return;
    }

    const request: HoldUnholdRequest = {
      clientId: this.clientId,
      documents: this.getDocumentData()
    };

    this.documentsService.unholdDocuments(request).subscribe({
      next: (response) => {
        this.messageService.add({ severity: 'info', summary: messages.unholdDocuments, detail: messages.unholdDocumentsTriggered });
        this.commonService.refreshOnOperationComplete(true);
      }, error: (error) => {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: messages.errorOccurred });
        console.error(error);
        //this.messageService.add({ severity: 'error', summary: messages.unholdDocuments, detail: error });
      }
    });
  }

  goToRecoverScreen() {
    const newTabUrl: string = '/recover';
    const url = this.router.serializeUrl(this.router.createUrlTree([newTabUrl]));
    window.open(url, '_blank');
    let userid = this.userId;
    localStorage.setItem('userId', userid);
  }

  editMetadata(retainShowUserPrompt: boolean) {
    if (this.editDisabled == true) {
      return;
    }
    this.showUserPrompt = retainShowUserPrompt;
    if (!retainShowUserPrompt) {
      this.disableEngagement = false;
      this.disableWorkflow = false;
    }

    this.IsEditSidebarVisible = true;

    let docId = this.selectedDocuments[0]?.key || '';

    // get default values for getMetadata object and bind
    this.documentsService.getDocumentsById(docId, this.clientId).subscribe({
      next: (response) => {
        this.getMetadata.taxpayers = response.taxpayers ?? null;
        //sort in alphabetical order
        this.getMetadata.taxpayers = this.getMetadata.taxpayers?.sort((a: any, b: any) => a.name.localeCompare(b.name));
        this.getMetadata.taxpayerId = response.taxpayerId ?? null;
        if (this.getMetadata.taxpayers != null && this.getMetadata.taxpayerId != null) {
          this.getMetadata.selectedTaxpayer = this.getMetadata.taxpayers.find(option => option.value === this.getMetadata.taxpayerId);
        }

        this.getMetadata.engagements = response.engagements ?? null;
        this.getMetadata.engagements = this.getMetadata.engagements?.sort((a: any, b: any) => a.name?.localeCompare(b.name));
        this.getMetadata.engagementId = response.engagementId ?? null;
        if (this.getMetadata.engagements != null && this.getMetadata.engagementId != null) {
          this.getMetadata.selectedEngagement = this.getMetadata.engagements.find(option => option.value === this.getMetadata.engagementId);
        }

        this.getMetadata.workflows = response.workflows ?? null;
        this.getMetadata.workflows = this.getMetadata.workflows?.sort((a: any, b: any) => a.name.localeCompare(b.name));
        this.getMetadata.workflowId = response.workflowId ?? null;
        if (this.getMetadata.workflows != null && this.getMetadata.workflowId != null) {
          this.getMetadata.selectedWorkflow = this.getMetadata.workflows.find(option => option.value === this.getMetadata.workflowId);
        }
        this.showUserPrompt = !this.getMetadata.engagementId && !this.getMetadata.workflowId;

        this.getMetadata.doctypes = response.doctypes ?? null;
        this.getMetadata.doctypes = this.getMetadata.doctypes?.sort((a: any, b: any) => a.name.localeCompare(b.name));
        this.getMetadata.doctypeId = response.doctypeId ?? null;
        if (this.getMetadata.doctypes != null && this.getMetadata.doctypeId != null) {
          this.getMetadata.selectedDoctype = this.getMetadata.doctypes.find(option => option.value === this.getMetadata.doctypeId);
        }

        this.getMetadata.doctypeGroups = response.doctypeGroups ?? null;
        this.getMetadata.doctypeGroups = this.getMetadata.doctypeGroups?.sort((a: any, b: any) => a.name.localeCompare(b.name));
        this.getMetadata.doctypeGroupId = response.doctypeGroupId ?? null;
        if (this.getMetadata.doctypeGroups != null && this.getMetadata.doctypeGroupId != null) {
          this.getMetadata.selectedDoctypeGroup = this.getMetadata.doctypeGroups.find(option => option.value === this.getMetadata.doctypeGroupId);
        }

        this.getMetadata.doctypeDescs = response.doctypeDescs ?? null;
        this.getMetadata.doctypeDescs = this.getMetadata.doctypeDescs?.sort((a: any, b: any) => a.name.localeCompare(b.name));
        this.getMetadata.doctypeDescId = response.doctypeDescId ?? null;
        if (this.getMetadata.doctypeDescs != null && this.getMetadata.doctypeDescId != null) {
          this.getMetadata.selectedDoctypeDesc = this.getMetadata.doctypeDescs.find(option => option.value === this.getMetadata.doctypeDescId);
        }

        this.getMetadata.doctype2ndDescs = response.doctype2ndDescs ?? null;
        this.getMetadata.doctype2ndDescs = this.getMetadata.doctype2ndDescs?.sort((a: any, b: any) => a.name.localeCompare(b.name));
        this.getMetadata.doctype2ndDescId = response.doctype2ndDescId ?? null;
        if (this.getMetadata.doctype2ndDescs != null && this.getMetadata.doctype2ndDescId != null) {
          this.getMetadata.selectedDoctype2ndDesc = this.getMetadata.doctype2ndDescs.find(option => option.value === this.getMetadata.doctype2ndDescId);
        }

        this.getMetadata.userDefineDescription = response.userDefineDescription ?? null;

        this.getMetadata.applicableTaxYear = response.applicableTaxYear ?? null;

        if (this.getMetadata.applicableTaxYear) {
          let yearList = this.getMetadata.applicableTaxYear.split(",");
          this.selectedYearsForEdit = this.years.filter((year: any) => { return yearList.includes(year.name); });
        }

        this.getMetadata.taxYear = response.taxYear ?? null;

        if (this.getMetadata.taxYear) {
          // this.selectedTaxYearForEdit = { name: this.getMetadata.taxYear, code: this.getMetadata.taxYear };
          this.selectedTaxYearForEdit = this.years.filter((year: any) => year.name == this.getMetadata.taxYear)[0];
        }

        if (this.disableEngagement == true && this.disableWorkflow == true) {
          this.selectedTaxYearForEdit = { name: "", code: "" };
          this.taxyeardisabled = false;
        }
        else {
          this.taxyeardisabled = true;
        }

        this.updateValidation();
      },
      error: (error) => {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: messages.failedFetch });
        console.error(error);
      }
    });

    this.commonService.disableRowSelectOnPanel(true);

  }

  openDocsInBrowser() {
    if (this.openInBrowserDisabled) {
      return;
    }

    for (let doc of this.selectedDocuments) {
      let browserParameter = "?&web=1";
      let modifiedUrl = doc.url + browserParameter;
      console.log(encodeURI(modifiedUrl));
      try {
        window.open(decodeURI(modifiedUrl), '_blank', 'noreferrer');
      }
      catch (error) {
        console.error(error);
      }
    }
    this.commonService.refreshOnOperationComplete(true)
  }

  getSignOffTooltip() {
    if (this.selectedDocuments.length == 0) {
      return "Please select a document for sign off"
    }
    else if (this.selectedDocuments.length > 1) {
      return "Please select only one document for sign off"
    }
    else if (this.selectedDocuments.length == 1 && !this.selectedDocuments[0]?.url) {
      return "Please wait until the document is fully uploaded"
    }
    else return ""
  }

  getEditTooltip() {
    if (this.selectedDocuments.length == 1) {
      if (this.selectedDocuments[0]?.retentionlabel && this.selectedDocuments[0]?.statuscode == 'Archived') {
        return "You can't edit this document. It is archived and has retention label applied"
      }
      else if (this.selectedDocuments[0]?.statuscode == 'Archived') {
        return "You can't edit this document. It is read-only"
      }
      else return ""
    }
    else if (this.selectedDocuments.length > 1) {
      return "Please select only one document for editing"
    }
    else return ""
  }

  updateValidation() {

    if (this.isUploading || this.isUpdating) {
      return true;
    }

    if (!this.getMetadata.selectedTaxpayer || (!this.disableEngagement && !this.getMetadata.selectedEngagement)
      || (!this.disableWorkflow && !this.getMetadata.selectedWorkflow)
      || !this.getMetadata.selectedDoctypeGroup || !this.getMetadata.selectedDoctype || !this.getMetadata.selectedDoctypeDesc
      || (this.isEditSecondaryDescriptionRequired && !this.getMetadata.selectedDoctype2ndDesc) || !this.selectedTaxYearForEdit.code /*!this.getMetadata.taxYear*/) {
      return true;
    }

    return false;
  }

  getMetadataObject(): MetadataUpdate {
    let dto = new MetadataUpdate();
    dto.clientId = this.clientId;
    dto.documentId = this.selectedDocuments[0]?.key;
    dto.applicableTaxYear = this.getMetadata.applicableTaxYear;
    dto.engagementTaxYear = this.getMetadata.engagementTaxYear?.toString();
    dto.taxYear = this.getMetadata.taxYear?.toString();
    dto.doctype2ndDescId = this.getMetadata.selectedDoctype2ndDesc?.value;
    dto.doctypeDescId = this.getMetadata.selectedDoctypeDesc?.value;
    dto.doctypeGroupId = this.getMetadata.selectedDoctypeGroup?.value;
    dto.doctypeId = this.getMetadata.selectedDoctype?.value;
    dto.engagementId = this.getMetadata.selectedEngagement?.value;
    dto.taxpayerId = this.getMetadata.selectedTaxpayer?.value;
    dto.workflowId = this.getMetadata.selectedWorkflow?.value;
    dto.userDefineDescription = this.getMetadata.userDefineDescription;
    dto.userEmailId = this.username;
    return dto;
  }

  updateDocuments() {
    if (!this.updateValidation()) {
      this.isUpdating = true;
      this.updateValidation();
      let documents = this.getMetadataObject();
      console.log(documents);

      this.documentsService.updateDocuments(documents)
        .subscribe({
          next: (response) => {
            this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Update successfully' });
            this.commonService.refreshTreeOnOperationComplete(true);
            this.commonService.refreshOnOperationComplete(true);
            this.isUpdating = false;
            this.IsEditSidebarVisible = false;
            this.onCloseSidebar('edit');
          },
          error: (error) => {
            this.messageService.add({ severity: 'error', summary: 'Error', detail: messages.failedUpdate });
            console.error(error);
            this.isUpdating = false;
          }
        });
    }
  }

  disableOrEnableEngagementAndWorkflow(isDisabled: boolean, taxPayer: any) {
    this.disableEngagement = isDisabled;
    this.disableWorkflow = isDisabled;
    this.getMetadata.engagements = [];
    if (!isDisabled) {
      this.metadataChangedForEdit(taxPayer, 'taxpayer');
    }
    else {
      this.editMetadata(true);
    }
    //if (isDisabled == true) {
    //  this.selectedTaxYearForEdit = { name: "", code: "" };
    //  this.taxyeardisabled = false;
    //}
    //else {
    //  this.taxyeardisabled = true;
    //}
  }

  checkOutDocuments() {
    if (this.selectedDocuments.length > 1) {
      this.messageService.add({
        severity: 'warn', summary: messages.checkOutDocument, detail: messages.multipleDocCheckoutError
      });
    }
    else if (this.selectedDocuments[0]?.doccheckoutstatus === 'Checked Out') {
      this.messageService.add({
        severity: 'warn', summary: messages.checkOutDocument, detail: messages.checkOutValidation
      });
    }
    else if (this.selectedDocuments[0]?.statuscode === 'Archived') {
      this.messageService.add({ severity: 'warn', summary: 'Check Out', detail: messages.archivedValidationErrorSingleDoc });
    }
    else {
      const request: CheckInCheckOutRequest = {
        documentId: this.selectedDocuments[0]?.key || '',
        documentURL: this.selectedDocuments[0]?.url || '',
        checkedBy: this.userId
      };
      this.graphQLService.checkOutDocument(request).subscribe({
        next: (response) => {
          this.messageService.add({ severity: 'info', summary: messages.checkOutDocument, detail: messages.checkOutSuccess });
          this.commonService.refreshOnOperationComplete(true);
        }, error: (error) => {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: messages.checkOutFailure });
          console.error(error);
          //this.messageService.add({ severity: 'error', summary: messages.checkOutDocument, detail: messages.checkOutFailure });
        }
      });
    }
  }

  checkInDocuments() {
    if (this.selectedDocuments.length > 1) {
      this.messageService.add({
        severity: 'warn', summary: messages.checkInDocument, detail: messages.multipleDocCheckinError
      });
    } else if (this.selectedDocuments[0]?.doccheckoutstatus !== 'Checked Out') {
      this.messageService.add({
        severity: 'warn', summary: messages.checkInDocument, detail: messages.checkInValidation
      });
    }
    else if (this.selectedDocuments[0]?.statuscode === 'Archived') {
      this.messageService.add({ severity: 'warn', summary: 'Check In', detail: messages.archivedValidationErrorSingleDoc });
    }
    else {
      const request: CheckInCheckOutRequest = {
        documentId: this.selectedDocuments[0]?.key,
        documentURL: this.selectedDocuments[0]?.url,
        checkedBy: this.userId
      };
      this.graphQLService.checkInDocument(request).subscribe({
        next: (response) => {
          this.messageService.add({ severity: 'info', summary: messages.checkInDocument, detail: messages.checkInSuccess });
          this.commonService.refreshOnOperationComplete(true);
        }, error: (error) => {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: messages.checkInFailure });
          console.error(error);
          //this.messageService.add({ severity: 'error', summary: messages.checkInDocument, detail: messages.checkInFailure });
        }
      });
    }
  }

  reloadSignOff() {
    this.commonService.refreshOnOperationComplete(true);
  }

}
