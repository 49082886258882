<div>
  <div class="row">
    <p class="h5 m-1">Documents</p>
  </div>
  <p-toast></p-toast>
  <div class="card flex flex-wrap justify-content-center gap-5">
    <p-tree #tt [value]="files | truncatetreedata" class="w-full" selectionMode="single"
            [(selection)]="selectedFile" [filter]="false" [loading]="isTreeLoading"
            (onNodeSelect)="nodeSelect($event)" filterPlaceholder="Search Documents Tree">

      <ng-template pTemplate="header">
        <div class="ui-widget-header ui-corner-all ui-tree-filter-container">
          <span class="p-input-icon-right" style="width: 100%;">
            <i class="pi pi-search"></i>
            <input type="search" pInputText [(ngModel)]="treeval" style="width: 100%;"
                   placeholder="Search Documents Tree" (input)="tt._filter(treeval)" />
          </span>

        </div>
        <div class="ui-widget-header ui-corner-all ui-tree-filter-container">
          <p-dropdown [options]="engagementYears" [(ngModel)]="selectedEngagementYears" placeholder="Select Engagement Year"
                      [style]="{'width':'100%', 'height':'35px', 'margin-top':'5px'}" class="w-full"
                      [showClear]="true" optionLabel="name"
                      (onChange)="tt._filter(selectedEngagementYears?.code ?? '') ">

          </p-dropdown>
        </div>

        <div class="ui-widget-header ui-corner-all ui-tree-filter-container">
          <p-dropdown [options]="workflowYears" [(ngModel)]="selectedWorkflowYears" placeholder="Select Workflow Year"
                      [style]="{'width':'100%', 'height':'35px', 'margin-top':'5px'}" class="w-full"
                      [showClear]="true" optionLabel="name"
                      (onChange)="tt._filter(selectedWorkflowYears?.code ?? '') ">
          </p-dropdown>
        </div>

      </ng-template>
    </p-tree>

  </div>
</div>


