<div class="row bg-white btn-container-row">

  <div class="mb-2 ms-2 col-6">
    <input #box type="search" pInputText style="width: 100%;"
           [(ngModel)]="searchInput" (ngModelChange)="onSearchChange($event)" placeholder="Search content of documents" />
  </div>
  <div class="col-1">
    <p-button label="Search" icon="pi pi-search" style="position: relative; top: 0px; line-height: 15px; margin-right: -15px;"
              styleClass="p-button-raised p-button-text me-3" (click)="contentSearch(box.value)">
      <!--<i class="pi pi-search"></i>-->
    </p-button>
  </div>
</div>

<p-toast></p-toast>
<!--scrollHeight="400px"-->
<p-table #dt1
         [value]="filteredCustomers"
         [(selection)]="selectedCustomers"
         dataKey="key"
         [paginator]="true"
         [rows]="10"
         [showCurrentPageReport]="true"
         [globalFilterFields]="['doctypedescordoctype2nddesc', 'userdefineddescription', 'preparer', 'detailreviewer', 'substantivereviewer', 'requiredreviewer', 'signer' , 'officeadmin', 'projectmanager', 'taxpayer', 'engagement', 'engagementnumber', 'year', 'workflow', 'workflowyear', 'senttogpdate', 'senttosureprepdate', 'lastmodifieddate', 'document', 'taskname', 'applicabletaxyear', 'market', 'region', 'retentionlabel', 'retentionLabelAppliedOn']"
         [tableStyle]="{ 'width': '100%' }"
         currentPageReportTemplate="Showing {first} to {last} of {totalRecords} documents"
         [rowsPerPageOptions]="[10,25,50, 100, 200,500]"
         (onRowSelect)="onRowSelect($event)"
         (onRowUnselect)="onRowUnselect($event)"
         (onHeaderCheckboxToggle)="onHeaderCheckboxToggle($event)"
         [metaKeySelection]="false"
         [loading]="isSignOffMatrixDataLoading"
         [scrollable]="true"
         [resizableColumns]="true"
         columnResizeMode="expand"
         styleClass="p-datatable-sm">

  <ng-template pTemplate="header">
    <tr>
      <th pResizableColumn alignFrozen="left" pFrozenColumn [frozen]="true" style="width: 20px"></th>
      <th pResizableColumn alignFrozen="left" pFrozenColumn [frozen]="true" style="width: 20px"></th>
      <th pResizableColumn alignFrozen="left" pFrozenColumn [frozen]="true" style="width: 20px"></th>
      <th pResizableColumn pSortableColumn="doctypedescordoctype2nddesc" alignFrozen="left" pFrozenColumn [frozen]="true" style="min-width:200px">Description<p-sortIcon field="doctypedescordoctype2nddesc"></p-sortIcon></th>
      <th pResizableColumn pSortableColumn="userdefineddescription" alignFrozen="left" pFrozenColumn [frozen]="true" style="min-width:200px">User Defined Description<p-sortIcon field="userdefineddescription"></p-sortIcon></th>
      <th pResizableColumn pSortableColumn="preparer" style="min-width:150px">Preparer<span class="required-field">*</span><p-sortIcon field="preparer"></p-sortIcon></th>
      <th pResizableColumn pSortableColumn="detailreviewer" style="min-width: 150px">Detail <br /> Reviewer<span class="required-field">*</span><p-sortIcon field="detailreviewer"></p-sortIcon></th>
      <th pResizableColumn pSortableColumn="substantivereviewer" style="min-width: 150px">Substantive <br /> Reviewer<p-sortIcon field="substantivereviewer"></p-sortIcon></th>
      <th pResizableColumn pSortableColumn="requiredreviewer" style="min-width: 150px ">Required <br /> Reviewer<p-sortIcon field="requiredreviewer"></p-sortIcon></th>
      <th pResizableColumn pSortableColumn="signer" style="min-width: 150px ">Signer<p-sortIcon field="signer"></p-sortIcon></th>
      <th pResizableColumn pSortableColumn="officeadmin" style="min-width: 150px ">Office <br /> Admin<p-sortIcon field="officeadmin"></p-sortIcon></th>
      <th pResizableColumn pSortableColumn="projectmanager" style="min-width: 150px ">Project <br /> Manager<p-sortIcon field="projectmanager"></p-sortIcon></th>
      <th pResizableColumn pSortableColumn="taxpayer" style="min-width: 150px ">Taxpayer<p-sortIcon field="taxpayer"></p-sortIcon></th>
      <th pResizableColumn pSortableColumn="engagement" style="min-width: 150px ">Engagement<p-sortIcon field="engagement"></p-sortIcon></th>
      <th pResizableColumn pSortableColumn="engagementnumber" style="min-width: 150px ">Engagement <br /> Number<p-sortIcon field="engagementnumber"></p-sortIcon></th>
      <th pResizableColumn pSortableColumn="year" style="min-width: 150px ">Engagement <br /> Year<p-sortIcon field="year"></p-sortIcon></th>
      <th pResizableColumn pSortableColumn="workflow" style="min-width: 150px ">Workflow<p-sortIcon field="workflow"></p-sortIcon></th>
      <th pResizableColumn pSortableColumn="workflowyear" style="min-width: 150px ">Tax <br /> Year<p-sortIcon field="workflowyear"></p-sortIcon></th>
      <th pResizableColumn pSortableColumn="senttogpdate" style="min-width: 150px ">Sent to <br /> GP Date<p-sortIcon field="senttogpdate"></p-sortIcon></th>
      <th pResizableColumn pSortableColumn="senttosureprepdate" style="min-width: 150px ">Sent to <br /> SurePrep Date<p-sortIcon field="senttosureprepdate"></p-sortIcon></th>
      <th pResizableColumn pSortableColumn="lastmodifieddate" style="min-width: 150px ">Modified <br /> Date<p-sortIcon field="lastmodifieddate"></p-sortIcon></th>
      <th pResizableColumn pSortableColumn="document" style="min-width: 150px ">File <br /> Name<p-sortIcon field="document"></p-sortIcon></th>
      <th pResizableColumn pSortableColumn="taskname" style="min-width: 150px ">Task <br /> Name<p-sortIcon field="taskname"></p-sortIcon></th>
      <th pResizableColumn pSortableColumn="applicabletaxyear" style="min-width: 150px ">Applicable <br /> Tax Year<p-sortIcon field="applicabletaxyear"></p-sortIcon></th>
      <th pResizableColumn pSortableColumn="market" style="min-width: 150px ">Market<p-sortIcon field="market"></p-sortIcon></th>
      <th pResizableColumn pSortableColumn="region" style="min-width: 150px ">Region<p-sortIcon field="region"></p-sortIcon></th>
      <th pResizableColumn pSortableColumn="retentionlabel" style="min-width: 150px ">Retention <br /> Label<p-sortIcon field="retentionlabel"></p-sortIcon></th>
      <th pResizableColumn pSortableColumn="retentionLabelAppliedOn" style="min-width: 150px ">Retention Label<br /> Applied On<p-sortIcon field="retentionLabelAppliedOn"></p-sortIcon></th>
      <th pResizableColumn pSortableColumn="statuscode" style="min-width: 150px ">Status<p-sortIcon field="statuscode"></p-sortIcon></th>
    </tr>
    <tr>

      <th alignFrozen="left" pFrozenColumn [frozen]="true" >
        <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
      </th>
      <th alignFrozen="left" pFrozenColumn [frozen]="true" >
        <div></div>
      </th>
      <th alignFrozen="left" pFrozenColumn [frozen]="true" >
        <div></div>
      </th>
      <th alignFrozen="left" pFrozenColumn [frozen]="true">
        <p-columnFilter type="text" field="doctypedescordoctype2nddesc"></p-columnFilter>
      </th>
      <th alignFrozen="left" pFrozenColumn [frozen]="true">
        <p-columnFilter type="text" field="userdefineddescription"></p-columnFilter>
      </th>
      <th>
        <p-columnFilter type="text" field="preparer"></p-columnFilter>
      </th>
      <th>
        <p-columnFilter type="text" field="detailreviewer"></p-columnFilter>
      </th>
      <th>
        <p-columnFilter type="text" field="substantivereviewer"></p-columnFilter>
      </th>
      <th>
        <p-columnFilter type="text" field="requiredreviewer"></p-columnFilter>
      </th>
      <th>
        <p-columnFilter type="text" field="signer"></p-columnFilter>
      </th>
      <th>
        <p-columnFilter type="text" field="officeadmin"></p-columnFilter>
      </th>
      <th>
        <p-columnFilter type="text" field="projectmanager"></p-columnFilter>
      </th>
      <th>
        <p-columnFilter type="text" field="taxpayer"></p-columnFilter>
      </th>
      <th>
        <p-columnFilter type="text" field="engagement"></p-columnFilter>
      </th>
      <th>
        <p-columnFilter type="text" field="engagementnumber"></p-columnFilter>
      </th>
      <th>
        <p-columnFilter type="text" field="year"></p-columnFilter>
      </th>
      <th>
        <p-columnFilter type="text" field="workflow"></p-columnFilter>
      </th>
      <th>
        <p-columnFilter type="text" field="workflowyear"></p-columnFilter>
      </th>
      <th>
        <p-columnFilter type="date" field="senttogpdate"></p-columnFilter>
      </th>
      <th>
        <p-columnFilter type="date" field="senttosureprepdate"></p-columnFilter>
      </th>
      <th>
        <p-columnFilter type="date" field="lastmodifieddate"></p-columnFilter>
      </th>
      <th>
        <p-columnFilter style="height:30px" type="text" field="document"></p-columnFilter>
      </th>
      <th>
        <p-columnFilter type="text" field="taskname"></p-columnFilter>
      </th>
      <th>
        <p-columnFilter type="text" field="applicabletaxyear"></p-columnFilter>
      </th>
      <th>
        <p-columnFilter type="text" field="market"></p-columnFilter>
      </th>
      <th>
        <p-columnFilter type="text" field="region"></p-columnFilter>
      </th>
      <th>
        <p-columnFilter type="text" field="retentionlabel"></p-columnFilter>
      </th>
      <th>
        <p-columnFilter type="date" field="retentionLabelAppliedOn"></p-columnFilter>
      </th>
      <th>
        <p-columnFilter type="text" field="statuscode"></p-columnFilter>
      </th>
    </tr>

  </ng-template>
  <ng-template pTemplate="body" let-customer>
    <tr [ngClass]="{ 'bg-yellow-100': shouldHighlightRow(customer.key), '': !shouldHighlightRow(customer.key), 'opacity-50':disableRowSelection==true }">

      <td pFrozenColumn><p-tableCheckbox [value]="customer" [disabled]="disableRowSelection"></p-tableCheckbox></td>
      <td pFrozenColumn>
        <div>
          <span *ngIf="customer.doccheckoutstatus === 'Checked Out'">
            <i class="pi pi-sign-out" style="color:red"></i>
          </span>
        </div>
      </td>
      <!-- File type icon -->
      <td pFrozenColumn>
        <div>
          <span>
            <!--<i [ngClass]="getFileTypeIconClass(customer.document)"></i>-->
            <i>
              <img [src]="getFileTypeIconClass(customer.document)" />
            </i>
          </span>
        </div>
      </td>
      <td pFrozenColumn>
        <div pTooltip="{{customer.doctypedescordoctype2nddesc}}" tooltipPosition="top">
          <div *ngIf="isSignoffDateChanged(customer.modifiedOn, customer.lastmodifieddate, customer.preparersignoffdate, customer.detailreviewersignoffdate, customer.key) === true" pTooltip="This document has been changed since the last sign off date. Please review changes and sign off.">
            <a *ngIf="customer.nativeAppUrl" href="{{customer.nativeAppUrl}}" target="_blank" style="text-decoration: none">{{ customer.doctypedescordoctype2nddesc | truncate }}</a>
            <span *ngIf="!customer.nativeAppUrl" style="text-decoration: none">{{ customer.doctypedescordoctype2nddesc | truncate }}</span>
          </div>
          <div *ngIf="isSignoffDateChanged(customer.modifiedOn, customer.lastmodifieddate, customer.preparersignoffdate, customer.detailreviewersignoffdate, customer.key) === false">
            <a *ngIf="customer.nativeAppUrl" href="{{customer.nativeAppUrl}}" target="_blank" style="text-decoration: none">{{ customer.doctypedescordoctype2nddesc | truncate }}</a>
            <span *ngIf="!customer.nativeAppUrl" style="text-decoration: none">{{ customer.doctypedescordoctype2nddesc | truncate }}</span>
          </div>
        </div>
      </td>

      <td pFrozenColumn>
        <div pTooltip="{{customer.userdefineddescription}}" tooltipPosition="top">
          {{ customer.userdefineddescription | truncate }}
        </div>
      </td>

      <td>
        <div *ngIf="customer.preparer === '' && customer.preparersignoffdate === ''">
          {{ customer.preparer | changetoinitials:customer.preparersignoffdate }}
        </div>
        <div *ngIf="customer.preparer != '' && customer.preparersignoffdate != ''" pTooltip="Signed off by {{customer.preparer}} on {{customer.preparersignoffdate | formatdate }}" tooltipPosition="top">
          {{ customer.preparer | changetoinitials:customer.preparersignoffdate }}
        </div>
      </td>

      <td>
        <div *ngIf="customer.detailreviewer === '' && customer.detailreviewersignoffdate === ''">
          {{ customer.detailreviewer | changetoinitials:customer.detailreviewersignoffdate }}
        </div>
        <div *ngIf="customer.detailreviewer != '' && customer.detailreviewersignoffdate != ''" pTooltip="Signed off by {{customer.detailreviewer}} on {{customer.detailreviewersignoffdate | formatdate }}" tooltipPosition="top">
          {{ customer.detailreviewer | changetoinitials:customer.detailreviewersignoffdate }}
        </div>
      </td>

      <td>
        <div *ngIf="customer.substantivereviewer === '' && customer.substantivereviewersignoffdate === ''">
          {{ customer.substantivereviewer | changetoinitials:customer.substantivereviewersignoffdate }}
        </div>
        <div *ngIf="customer.substantivereviewer != '' && customer.substantivereviewersignoffdate != ''" pTooltip="Signed off by {{customer.substantivereviewer}} on {{customer.substantivereviewersignoffdate | formatdate }}" tooltipPosition="top">
          {{ customer.substantivereviewer | changetoinitials:customer.substantivereviewersignoffdate }}
        </div>
      </td>

      <td>
        <div *ngIf="customer.requiredreviewer === '' && customer.requiredreviewersignoffdate === ''">
          {{ customer.requiredreviewer | changetoinitials:customer.requiredreviewersignoffdate }}
        </div>
        <div *ngIf="customer.requiredreviewer != '' && customer.requiredreviewersignoffdate != ''" pTooltip="Signed off by {{customer.requiredreviewer}} on {{customer.requiredreviewersignoffdate | formatdate }}" tooltipPosition="top">
          {{ customer.requiredreviewer | changetoinitials:customer.requiredreviewersignoffdate }}
        </div>
      </td>

      <td>
        <div *ngIf="customer.signer === '' && customer.signersignoffdate === ''">
          {{ customer.signer | changetoinitials:customer.signersignoffdate }}
        </div>
        <div *ngIf="customer.signer != '' && customer.signersignoffdate != ''" pTooltip="Signed off by {{customer.signer}} on {{customer.signersignoffdate | formatdate }}" tooltipPosition="top">
          {{ customer.signer | changetoinitials:customer.signersignoffdate }}
        </div>
      </td>

      <td>
        <div *ngIf="customer.officeadmin === '' && customer.officeadminsignoffdate === ''">
          {{ customer.officeadmin | changetoinitials:customer.officeadminsignoffdate }}
        </div>
        <div *ngIf="customer.officeadmin != '' && customer.officeadminsignoffdate != ''" pTooltip="Signed off by {{customer.officeadmin}} on {{customer.officeadminsignoffdate | formatdate }}" tooltipPosition="top">
          {{ customer.officeadmin | changetoinitials:customer.officeadminsignoffdate }}
        </div>
      </td>

      <td>
        <div *ngIf="customer.projectmanager === '' && customer.projectmanagersignoffdate === ''">
          {{ customer.projectmanager | changetoinitials:customer.projectmanagersignoffdate }}
        </div>
        <div *ngIf="customer.projectmanager != '' && customer.projectmanagersignoffdate != ''" pTooltip="Signed off by {{customer.projectmanager}} on {{customer.projectmanagersignoffdate | formatdate }}" tooltipPosition="top">
          {{ customer.projectmanager | changetoinitials:customer.projectmanagersignoffdate }}
        </div>
      </td>

      <td>
        <div pTooltip="{{customer.taxpayer}}" tooltipPosition="top">
          {{ customer.taxpayer | truncatestring }}
        </div>
      </td>

      <td>
        <div pTooltip="{{customer.engagement}}" tooltipPosition="top">
          {{ customer.engagement | truncatestring }}
        </div>
      </td>

      <td>
        <div pTooltip="{{customer.engagementnumber}}" tooltipPosition="top">
          {{ customer.engagementnumber | truncatestring }}
        </div>
      </td>

      <td>
        <div pTooltip="{{customer.year}}" tooltipPosition="top">
          {{ customer.year | truncatestring }}
        </div>
      </td>

      <td>
        <div pTooltip="{{customer.workflow}}" tooltipPosition="top">
          {{ customer.workflow | truncatestring }}
        </div>
      </td>

      <td>
        <div pTooltip="{{customer.workflowyear}}" tooltipPosition="top">
          {{ customer.workflowyear | truncatestring }}
        </div>
      </td>

      <td>
        <div pTooltip="Sent to Global Portal on {{customer.senttogpdate | formatdate}}" tooltipPosition="top">
          {{ customer.senttogpdate | formatdate }}
        </div>
      </td>

      <td>
        <div pTooltip="Sent to Sure Prep on {{customer.senttosureprepdate | formatdate}}" tooltipPosition="top">
          {{ customer.senttosureprepdate | formatdate }}
        </div>
      </td>

      <td>
        <div pTooltip="Document last modified on {{customer.lastmodifieddate | formatdate}}" tooltipPosition="top">
          {{ customer.lastmodifieddate | formatdate }}
        </div>
      </td>

      <td>
        <div pTooltip="{{customer.document}}" tooltipPosition="top">
          {{ customer.document | truncatestring }}
        </div>
      </td>

      <td>
        <div pTooltip="{{customer.taskname}}" tooltipPosition="top">
          {{ customer.taskname | truncatestring }}
        </div>
      </td>

      <td>
        <div pTooltip="Applicable tax year(s) {{customer.applicabletaxyear}}" tooltipPosition="top">
          {{ customer.applicabletaxyear }}
        </div>
      </td>

      <td>
        <div pTooltip="{{customer.market}}" tooltipPosition="top">
          {{ customer.market | truncatestring }}
        </div>
      </td>

      <td>
        <div pTooltip="{{customer.region}}" tooltipPosition="top">
          {{ customer.region | truncatestring }}
        </div>
      </td>

      <td>
        <div pTooltip="{{customer.retentionlabel}}" tooltipPosition="top">
          {{ customer.retentionlabel }}
        </div>
      </td>

      <td>
        <div pTooltip="Rentention label applied on  : {{customer.retentionLabelAppliedOn | formatdate}}" tooltipPosition="top">
          {{customer.retentionLabelAppliedOn | formatdate}}
        </div>
      </td>

      <td>
        <div pTooltip="Status : {{customer.statuscode}}" tooltipPosition="top">
          {{ customer.statuscode }}
        </div>
      </td>

    </tr>
  </ng-template>
</p-table>
