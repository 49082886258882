import { Component, ViewChild } from '@angular/core';
import { SignOffMatrix } from '../../model/signoffmatrix';
import { documentsService } from '../services/documents.service';
import { ConfirmationService, MessageService } from 'primeng/api';
import { LocalService } from '../services/local.service';
import { ActivatedRoute } from '@angular/router';
import { Constant } from '../../model/constant.enums';
import { Context } from '../../model/context';
import { HttpParams } from '@angular/common/http';
import { CommonService } from '../services/common/common.service';
import { getRolesDTO } from '../../model/getRolesDTO';
import { messages } from '../messages/message.constant';

let ELEMENT_DATA: SignOffMatrix[]

@Component({
  selector: 'app-recover',
  templateUrl: './recover.component.html',
  styleUrls: ['./recover.component.css'],
  providers: [MessageService]
})
export class RecoverComponent {
  deletedDocuments: SignOffMatrix[] = [];
  selectedDocuments: SignOffMatrix[] = [];
  recoverdisabled: boolean = true;

  isDeleteMatrixDataLoading: boolean = false;

  parsedContext!: Context;
  clientId!: string;
  clientName!: string;

  userId!: string;

  constructor(private documentsService: documentsService, private messageService: MessageService,
    private confirmationService: ConfirmationService, private localService: LocalService,
    private route: ActivatedRoute, private commonService: CommonService) {
  }

  ngOnInit() {
    this.deletedDocuments = ELEMENT_DATA;
    this.getContext();
    this.getUserId();
  }

  private getContext() {
    let treecontext = this.localService.getData(Constant[Constant.context]);
    this.parsedContext = JSON.parse(treecontext ?? '');
    this.clientId = this.parsedContext.clientId;
    this.clientName = this.parsedContext.clientName;
    this.getDeletedDocuments(this.clientId);
  }

  getDeletedDocuments(clientId: any) {
    let parameters = new HttpParams();
    parameters = parameters.append('clientId', clientId.toLocaleLowerCase());
    this.isDeleteMatrixDataLoading = true;
    this.documentsService.getDeletedDocumentsList(parameters).subscribe({
      next: (result: any) => {
        let mappedData = result.map((item: any) => {
          return {
            position: item.mcsHierarchyWorkflow,
            document: item.mcsName,
            taskname: item.mcsTaskName,
            userdefineddescription: item.mcsUserDefinedDescription,
            preparer: item.mcsPreparer,
            detailreviewer: item.mcsDetailReviewer,
            substantivereviewer: item.mcsSubstantiveReviewer,
            signer: item.mcsSigner,
            officeadmin: item.mcsOfficeAdmin,
            projectmanager: item.mcsProjectManager,
            signersignoffdate: item.mcsSignerSignOffDate == '0001-01-01T00:00:00' ? null : item.mcsSignerSignOffDate,
            preparersignoffdate: item.mcsPreparerSignOffDate == '0001-01-01T00:00:00' ? null : item.mcsPreparerSignOffDate,
            detailreviewersignoffdate: item.mcsDetailReviewerSignOffDate == '0001-01-01T00:00:00' ? null : item.mcsDetailReviewerSignOffDate,
            substantivereviewersignoffdate: item.mcsSubstantiveReviewerSignOffDate == '0001-01-01T00:00:00' ? null : item.mcsSubstantiveReviewerSignOffDate,
            officeadminsignoffdate: item.mcsOfficeAdminSignOffDate == '0001-01-01T00:00:00' ? null : item.mcsOfficeAdminSignOffDate,
            projectmanagersignoffdate: item.mcsProjectManagerSignOffDate == '0001-01-01T00:00:00' ? null : item.mcsProjectManagerSignOffDate,
            senttogpdate: item.mcsSentToGlobalPortalDate == '0001-01-01T00:00:00' ? null : item.mcsSentToGlobalPortalDate,
            applicabletaxyear: item.mcsApplicableTaxYear,
            senttosureprepdate: item.mcsSentToSurePrepDate == '0001-01-01T00:00:00' ? null : item.mcsSentToSurePrepDate,
            lastmodifieddate: item.mcsDocLastModified == '0001-01-01T00:00:00' ? null : item.mcsDocLastModified,
            doctypedescordoctype2nddesc: item.mcsDoctypeDesc != '' ? item.mcsDoctypeDesc : item.mcsDoctype2ndDesc,
            market: "",
            region: "",
            key: item.mcsDocumentId,
            url: item.mcsDocUrl,
            taxpayer: item.mcsHierarchyTaxpayer,
            engagement: item.mcsHierarchyEngagement,
            workflow: item.mcsHierarchyWorkflow,
            year: item.mcsEngagementTaxYear,
            doctype: item.mcsDoctype,
            doctypedesc: item.mcsDoctypeDesc,
            doctype2nddesc: item.mcsDoctype2ndDesc,
            deletedon: item.mcsDeletedOn,
            doccheckoutstatus: item.mcsDocCheckOutStatus
          };
        });

        ELEMENT_DATA = mappedData;
        this.deletedDocuments = mappedData;
        console.log(this.deletedDocuments);
        this.isDeleteMatrixDataLoading = false;
      },
      error: (error) => {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: messages.failedSignOffMatrix });
        console.error(error);
        this.isDeleteMatrixDataLoading = false;
      }
    });

  }


  goToMainScreen() {
    window.close();
  }


  async isWorkflowStatusInactive(selectedDocs: any): Promise<boolean> {
    let selecteddocsworkflowstatus: string[] = [];
    let keyListSelectedDocs: string[] = this.selectedDocuments.map(x => x.key);
    let isWorkflowStatusInactive = false;
    try {
      selecteddocsworkflowstatus = await this.documentsService.getWorkflowStatusOfDocuments(keyListSelectedDocs).toPromise();
      isWorkflowStatusInactive = selecteddocsworkflowstatus.includes('Inactive');
    }
    catch (error) {
      console.error(error);
    }
    return isWorkflowStatusInactive;
  }


  async onRowSelect(event: any) {
    if (await this.isWorkflowStatusInactive(this.deletedDocuments)) {
      this.recoverdisabled = true;
    }
    else {
      this.recoverdisabled = false;
    }
  }

  async onRowUnselect(event: any) {
    if (this.selectedDocuments?.length == 0) {
      this.recoverdisabled = true;
    }
    if (await this.isWorkflowStatusInactive(this.deletedDocuments)) {
      this.recoverdisabled = true;
    }
    else {
      this.recoverdisabled = false;
    }
  }

  onHeaderCheckboxToggle(event: any) {
    if (event.checked) {
      this.recoverdisabled = false;
    }
    else {
      if (this.selectedDocuments?.length == 0) {
        this.recoverdisabled = true;
      }
    }
  }

  getUserId() {
    const userId = localStorage.getItem('userId');
    this.userId = userId ? userId : ""
  }

  recoverRows() {
    let keyListSelectedDocs: string[] = this.selectedDocuments.map(x => x.key);
    this.confirmationService.confirm({
      message: 'Are you sure you want to recover?',
      accept: () => {
        const data: getRolesDTO = new getRolesDTO();
        data.selectedDocuments = keyListSelectedDocs;
        data.username = this.userId;
        this.documentsService.recoverRows(data)
          .subscribe({
            next: (response) => {
              this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Recovered successfully' });
              this.getDeletedDocuments(this.clientId);
            },
            error: (error) => {
              this.messageService.add({ severity: 'error', summary: 'Error', detail: messages.failedRecovered });
              console.error(error);
            }
          });
      }
    });
  }

}
