import { SaveResult, EntityState, Predicate } from 'breeze-client';
import { MetaEntity } from '../../model/metaEntity';
import Strings from './strings';
import Config from './config';

const LOADING_SCREEN_CLASS = 'finished-loading';
const LOADING_SHELL_ID = 'loadingscreen';

export default class Utilities {
    static showLoadingScreen() {
        const loadingShell = document.getElementById(LOADING_SHELL_ID);
        if (loadingShell && hasClass(loadingShell, LOADING_SCREEN_CLASS)) {
            removeClass(loadingShell, LOADING_SCREEN_CLASS);
        }
    }

    static hideLoadingScreen() {
        const loadingShell = document.getElementById(LOADING_SHELL_ID);
        if (loadingShell && !hasClass(loadingShell, LOADING_SCREEN_CLASS)) {
            addClass(loadingShell, LOADING_SCREEN_CLASS);
        }
    }

	static decodeHtml(html: string) {
		const txt = document.createElement("textarea");
		txt.innerHTML = html;
		return txt.value;
	}

	static isMobileSafari() {
		return navigator.userAgent.match(/(iPod|iPhone|iPad)/) && navigator.userAgent.match(/AppleWebKit/) && !navigator.userAgent.match(/CriOS/);
	}

	static numberWithCommas(x: number, round?: number): string {
		let y = 0;
		if (x) {
			y = x;
		}

		const parts = (round === null ? y.toString() : y.toFixed(round)).split(".");
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		parts[0] = parts[0]!.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
		return parts.join(".");
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	static camelCaseDto(dto: any): any {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const m: any = {};
		// eslint-disable-next-line
		for (let [propName, value] of Object.entries(dto)) {
			// eslint-disable-next-line
			m[propName.charAt(0).toLowerCase() + propName.slice(1)] = value;
		}

		return m;
	}

	static formatSavedMessage(saveResult: SaveResult) {
		let result = "";

		saveResult.entities.forEach(function (rawEntity) {
			const entity = rawEntity as MetaEntity;
			if (entity.entityAspect.valueQueue) {
				if (entity.entityAspect.fieldsSavingEntityState === EntityState.Modified) {
					for (const field in entity.entityAspect.fieldsSaving) {
						const val = entity.entityAspect.valueQueue[field];
						if (val) {
							// eslint-disable-next-line
							val.orig = (entity as any)[field]();
							val.isSaved = true;
						}
					}
				}
			}

			if (result.length) {
				result += "<br />";
			}

			// eslint-disable-next-line no-prototype-builtins
			if (entity.entityAspect.fieldsSavingEntityState === EntityState.Deleted || (entity.hasOwnProperty("isDeleted") && entity.isDeleted && (entity.entityAspect.fieldsSaving['isDeleted'] !== undefined && entity.isDeleted === true))) {
				if (entity.entityAspect.delMsg) {
					result = entity.entityAspect.delMsg;
				} else if (entity.name) {
					result = Strings.toasts.entityDeleted.replace(/\{0\}/gi, entity.name);
				}
			// eslint-disable-next-line no-prototype-builtins
			} else if (entity.entityAspect.fieldsSavingEntityState === EntityState.Added || (entity.hasOwnProperty("isDeleted") && entity.isDeleted && (entity.entityAspect.fieldsSaving['isDeleted'] !== undefined && !entity.isDeleted))) {
				if (entity.entityAspect.addMsg) {
					result = entity.entityAspect.addMsg;
				} else if (entity.name) {
					result = Strings.toasts.entityAdded.replace(/\{0\}/gi, entity.name);
				}
			} else if (entity.entityAspect.fieldsSavingEntityState === EntityState.Modified) {
				for (const field in entity.entityAspect.fieldsSaving) {
					if (Config.skipFieldsForMessage.indexOf(field) >= 0) {
						continue;
					}

					// eslint-disable-next-line
					const fieldName = (entity.entityType.props[field] as any).displayName as string;
					// eslint-disable-next-line
					let fieldValue = (entity as any)[field];

					if (/.+Id$/gi.test(field)) {
						const actualField = field.replace(/Id$/gi, "");
						// eslint-disable-next-line
						if ((entity as any)[actualField]) {
							// eslint-disable-next-line
							const actualEntity = (entity as any)[actualField];
							// eslint-disable-next-line
							if (actualEntity && actualEntity.name) {
								// eslint-disable-next-line
								fieldValue = actualEntity.name;
							}
						}
					}

					// eslint-disable-next-line
					if (fieldValue && fieldValue.length > 28) {
						// eslint-disable-next-line
						fieldValue = fieldValue.substring(0, 25) + "...";
					}
					result += fieldName + ": " + fieldValue + "<br />";
				}
			}

			result = result.replace(/<br \/>$/gi, "");

			entity.entityAspect.fieldsSaving = {};
		});

		return result;
	}

	static tryParseInt(str: string, defaultValue: number | null, radix?: number): number | null {
		if (str !== null && str.length > 0) {
			const tried = parseInt(str, radix);
			if (!isNaN(tried))
				return tried;
		}
		return defaultValue;
	}

	static tryParseFloat(str: string, defaultValue: number | null): number | null {
		if (str !== null && str.length > 0) {
			const tried = parseFloat(str);
			if (!isNaN(tried))
				return tried;
		}
		return defaultValue;
	}

	static tryParseDate(str: string, defaultValue: string | Date | null): Date | null {
		if (str !== null && str.length > 0) {
			const tried = Date.parse(str);
			if (!isNaN(tried))
				return new Date(str);
		}
		if (defaultValue === null)
			return null;
		if (typeof defaultValue === 'string') {
			const triedDefault = Date.parse(defaultValue);
			if (!isNaN(triedDefault))
				return new Date(defaultValue);
		}
		throw new Error('defaultValue could not be parsed as a date');
	}
}

export function hasClass(el: HTMLElement, className: string)
{
    if (el.classList) {
        return el.classList.contains(className);
    }

    return !!el.className.match(new RegExp('(\\s|^)' + className + '(\\s|$)'));
}

export function addClass(el: HTMLElement, className: string)
{
    if (el.classList) {
        el.classList.add(className);
    }
    else if (!hasClass(el, className)) {
        el.className += " " + className;
    }
}

export function removeClass(el: HTMLElement, className: string)
{
    if (el.classList) {
        el.classList.remove(className);
    }
    else if (hasClass(el, className))
    {
        const reg = new RegExp('(\\s|^)' + className + '(\\s|$)');
        el.className = el.className.replace(reg, ' ');
    }
}

export interface QueryOneOptions {
    sort?: string;
    filter?: Predicate;
    include?: string[];
    noTracking?: boolean;
    select?: string[];
    skip?: number;
    take: 1;
    top?: number;
}

export interface QueryOptions {
    sort?: string;
    filter?: Predicate;
    include?: string[];
    noTracking?: boolean;
    select?: string[];
    skip?: number;
    take?: number;
    top?: number;
}

export interface InlineCountQueryOptions extends QueryOptions {
    inlineCount: true;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function instanceOfInlineCountQueryOptions(obj: any): obj is InlineCountQueryOptions {
    // eslint-disable-next-line
    return obj && "inlineCount" in obj && obj.inlineCount === true;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function instanceOfQueryOneOptions(obj: any): obj is QueryOneOptions {
    // eslint-disable-next-line
    return obj && "take" in obj && obj.take === 1 && !("inlineCount" in obj);
}

type Option<O extends QueryOptions> = O extends InlineCountQueryOptions ? InlineCountQueryOptions : O extends QueryOneOptions ? QueryOneOptions : QueryOptions;
export function queryOptions<O extends BreezeOptions = QueryOptions>(options?: O): Option<O> {
    // eslint-disable-next-line
    return options as any;
}

export type BreezeOptions = QueryOneOptions | QueryOptions | InlineCountQueryOptions;
export type BreezeResult<O, T> = O extends InlineCountQueryOptions ? inlineCountResult<T> : O extends QueryOneOptions ? T : T[];
export class inlineCountResult<T> {
    results: T[];
    inlineCount: number;

    constructor(results: T[], inlineCount: number) {
        this.results = results;
        this.inlineCount = inlineCount;
    }
}
