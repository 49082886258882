import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { catchError, Observable } from 'rxjs';
import { Documents } from '../../model/documents';
import { environment } from '../../environments/environment';
import { parseChallenges } from './claim-utils';
import { AccountInfo } from '@azure/msal-browser';
import { addClaimsToStorage } from './storage-utils';
import { msalConfig, protectedResources } from '../app.module';
import { MsalService } from '@azure/msal-angular';
import { CommonService } from './common/common.service';
import { CheckInCheckOutRequest } from '../../model/CheckInCheckOutRequest';
import { ContentSearchDTO } from '../../model/contentSearchDTO';

@Injectable({
  providedIn: 'root'
})
export class GraphQLService {
  private readonly url: string = environment.baseUrl + 'graphql';

  constructor(private http: HttpClient, private authService: MsalService,
    private commonService: CommonService) { }

  // Content search.
  contentSearch(request: ContentSearchDTO): Observable<any> {
    return this.http.post(`${this.url}/search`, request).pipe(
      catchError((error:any) => {
        console.log(error);

        if (error.status === 401 && error.headers.get('WWW-Authenticate')) {
          this.commonService.handleClaimsChallenge(error)
        }
        return error;
      })
    );
  }

  // Check out document
  checkOutDocument(request: CheckInCheckOutRequest): Observable<any> {
    return this.http.post(`${this.url}` + "/checkoutdocument", request).pipe(
      catchError((error:any) => {
        console.log(error);

        if (error.status === 401 && error.headers.get('WWW-Authenticate')) {
          this.commonService.handleClaimsChallenge(error)
        }
        return error;
      })
    );
  }

  // Check in document
  checkInDocument(request: CheckInCheckOutRequest): Observable<any> {
    return this.http.post(`${this.url}` + "/checkindocument", request).pipe(
      catchError((error:any) => {
        console.log(error);

        if (error.status === 401 && error.headers.get('WWW-Authenticate')) {
          this.commonService.handleClaimsChallenge(error)
        }
        return error;
      })
    );
  }

}
